import React from 'react'
import { DashboardIcon, LogOutIcon } from '../svg'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <nav
      id="sidebarMenu"
      className="col-xl-2 col-lg-3 d-lg-block sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to={'/dashboard'}>
              <span className='me-2'>
                <DashboardIcon />
              </span>
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/login" onClick={() => localStorage.clear()}>
              <span className='me-2'>
                <LogOutIcon />
              </span>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
