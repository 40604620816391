import React from 'react'
import Footer from '../components/Footer'
import SectionWaiting from '../components/SectionWaiting'
import Header from '../components/Header'
import { Link, useNavigate } from 'react-router-dom'

const detail = [
    {
        title: 'Danielle’s story',
        quotes: '“The offer made through CarMax was significantly more than anywhere else.”',
        discription: 'Danielle was delighted to be expecting her first child, and realised her three-door car wasn’t convenient or safe for a baby seat.',
        discription2: 'She wanted to sell quickly, for the right price, so she could upgrade – and found CarMax to be both prompt and easy to use.',
        link: '',
        img: "./assets/img/truestory/storylist/story1.webp"
    },
    {
        title: 'Nigel’s story',
        quotes: '“The price was so good, we sold our second car too!”',
        discription: 'Feeling the pinch, Nigel and his wife knew they couldn’t justify two cars anymore.',
        discription2: 'Having taken great care of his VW Tiguan, he got a much better price than expected, and they decided to sell their other car too.',
        link: '',
        img: "./assets/img/truestory/storylist/story2.webp"
    },
    {
        title: 'Sheila & Brian’s story',
        quotes: '“Brian’s recommended CarMax to all our friends! We can’t fault it.”',
        discription: 'Sheila told Brian that it was time to get a dog and a campervan, and tour the UK.',
        discription2: 'Arriving to rehome Bruce and seeing his scruffy face, Brian fell in love, and the plan was in motion.',
        link: '',
        img: "./assets/img/truestory/storylist/story3.webp"
    },
    ,
    {
        title: 'Emma’s story',
        quotes: '“Selling with CarMax was seamless, convenient, and I got a great price.”   ',
        discription: 'Emma and her ex-rugby player boyfriend struggled to share her tiny Toyota Aygo. ',
        discription2: 'They were ready to upgrade the car and start their next chapter – and selling online, from home, was the best way to fit Emma’s busy schedule.',
        link: '',
        img: "./assets/img/truestory/storylist/story4.webp"
    }
    ,
    {
        title: 'Ian’s story',
        quotes: '“I was over the moon.”',
        discription: 'The real-life star of our latest TV advert, Ian sold his Ford Focus and became his own boss.',
        discription2: 'Blown away by the winning bid for his Focus on CarMax, Ian used the extra funds to turn his dreams of becoming a handyman into a reality. ',
        link: '',
        img: "./assets/img/truestory/storylist/story5.webp"
    }
    ,
    {
        title: 'Craig & Melissa’s story',
        quotes: '“When the money came through, we felt like we’d won the lottery!”',
        discription: 'Craig and Melissa hadn’t initially planned to sell their dream car: a Mercedes GLA.',
        discription2: 'The costs of their dream honeymoon and necessary home renovations made it necessary to free up the capital invested in the car.',
        link: '',
        img: "./assets/img/truestory/storylist/story6.webp"
    },
    {
        title: 'Vikki’s story',
        quotes: '“I found out about CarMax from my son, and I got the car straight on the website.”',
        discription: 'Vikki loved her VW Golf, but the running costs were becoming unaffordable.',
        discription2: 'Being a single mum, she needed a safe solution to holding car viewings at home with strangers.',
        link: '',
        img: "./assets/img/truestory/storylist/story7.webp"
    },
    {
        title: 'John’s story',
        quotes: '“The service that we received through CarMax was literally second to none.”',
        discription: 'With the twins’ due date and a house move all coming at once, Lee and his wife had to sell their cars fast and upgrade to a seven-seater.',
        discription2: 'Working as a nurse, Lee appreciated the easy and swift sale, completed at his hospital for ultimate convenience.',
        link: '',
        img: "./assets/img/truestory/storylist/story8.webp"
    }
]

export default function TrueStories() {
    const navigat = useNavigate()
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100'>
                        <div className="text-center">
                            <h1 className='mb-3 main-heading color-dark'>
                                Real deals Real Prices
                            </h1>
                            <p className='mb-3 color-dark opacity-75'>
                                We found out what this extra money means for people who’ve sold their car on CarMax. Meet real customers,
                                <br />
                                who all made the most of selling their cars the more money way.
                            </p>
                        </div>
                        <div className='mb-5 mt-50'>
                            <div className="row g-3 ">
                                {detail.map((i, index) =>
                                    <div className="col-lg-6">
                                        <div className="story-border-card">
                                            <div className='d-flex flex-xl-row flex-column gap-3'>
                                                <div>
                                                    <h5 className='small-heading mb-0'>{i.title}</h5>
                                                    <p className='color-purplr mt-40 medium-heading'>
                                                        {i.quotes}
                                                    </p>
                                                    <p className='color-dark opacity-75 medium-heading'>
                                                        {i.discription}
                                                    </p>
                                                    <p className='color-dark opacity-75 medium-heading'>
                                                        {i.discription2}
                                                    </p>
                                                    <div className='mt-30'>
                                                        <button className='gray-btn border-0' onClick={() => navigat('/true-story-detail')}>
                                                            <span className='px-3'>
                                                                READ MORE
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <img src={i.img} alt="story img" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SectionWaiting />
            <Footer />
        </>
    )
}
