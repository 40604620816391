import React from 'react'
import { LogoPurpal } from '../svg'
import { Link, useNavigate } from 'react-router-dom'

export default function DashboardHeader() {

  const navigate = useNavigate()

  return (
    <header className="navbar  sticky-top flex-md-nowrap py-0 dashbord-heder">
      <Link className="navbar-brand col-md-3 col-lg-2 me-0" to="/">
        <LogoPurpal />
      </Link>
      <div className=''>
        <button
          className="navbar-toggler d-lg-none collapsed d-block"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-nav pe-4 d-lg-block d-none">
          <div className="nav-item text-nowrap">
            <div className="btn-group">
              <button
                type="button"
                className="dropdown-toggle header-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="/assets/img/dashboard/user.png" alt="user" className='img-fluid me-2' />
                {localStorage.getItem('full-name')}
              </button>
              <ul className="dropdown-menu dropdown-menu-end position-absolute header-toggle-menu">
                <li>
                  <button className="dropdown-item" type="button">
                    Dashboard
                  </button>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                  <button className="dropdown-item" type="button" onClick={() => {
                    localStorage.clear()
                    navigate('/login')
                  }}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
