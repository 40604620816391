import React, { useState } from 'react'
import Header from '../components/Header'
import { YellowLine } from '../components/svg'
import Footer from '../components/Footer'
import SectionWaiting from '../components/SectionWaiting'

const faqs = [
  {
    type: 'Selling a Car',
    id: 'SellingCard',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it1?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it2?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  },
  {
    type: 'Buying a Car',
    id: 'BuyingCard',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it3?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it4?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it5?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  },
  {
    type: 'Maintanance & more',
    id: 'Maintanance',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it6?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it7?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  },
  {
    type: 'Selling your car',
    id: 'Selling',
    items: [
      {
        title: `What is Lorem Ipsum?`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`
      },
      {
        title: `Why do we use it?`,
        description: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.`
      }
    ]
  },
  {
    type: 'Warranties & More',
    id: 'Warranties',
    items: [
      {
        title: `Where does it come from?`,
        description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock.`
      },
      {
        title: `Where can I get some?`,
        description: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.`
      }
    ]
  },
  {
    type: 'Warranties & More',
    id: 'Finding',
    items: [
      {
        title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet eget est quis iaculis?`,
        description: `Donec mattis non ligula sed egestas. Aliquam erat volutpat. Quisque dolor purus, mollis sit amet consequat id, pharetra sed mi. Phasellus suscipit ipsum ac lacus sagittis, at ullamcorper odio vestibulum. Integer vel elit nec justo feugiat elementum vitae sit amet urna.`
      },
      {
        title: `Nunc et odio gravida, mollis dui quis, consequat lorem. Sed in ante pulvinar, pretium augue non, posuere augue?`,
        description: `Suspendisse accumsan rhoncus sollicitudin. Etiam viverra mauris quis vehicula feugiat. Aenean quis feugiat ligula, porta eleifend ligula. Suspendisse et tempor orci. Phasellus bibendum condimentum neque, ac tincidunt justo auctor vel.`
      }
    ]
  },
  {
    type: 'Making a Payments',
    id: 'Making',
    items: [
      {
        title: `Suspendisse ut lorem a est dignissim pellentesque hendrerit sit amet ligula. Vivamus convallis iaculis?`,
        description: `Nulla facilisi. Fusce et efficitur diam. Quisque nec dictum tellus. Nam non massa ligula. Curabitur suscipit, felis in viverra efficitur, diam arcu ultricies ex, vel dictum nibh nulla at tortor.`
      },
      {
        title: `Is there a free trial available?`,
        description: `Yes, we offer a free trial for [duration, e.g., 7 days]. Sign up here to get started.`
      }
    ]
  },
  {
    type: 'Financing',
    id: 'Financing',
    items: [
      {
        title: `Aenean sodales libero quis lacus scelerisque, vel sodales diam tincidunt.`,
        description: `Cras nisi sapien, ultrices ac turpis id, iaculis venenatis ex. Proin sagittis accumsan odio, eget aliquet sem sollicitudin at. Integer sagittis commodo libero ut tristique. Donec a ipsum risus. Aenean ut fermentum nibh, id laoreet massa. Vivamus pretium elementum elit a lacinia.`
      },
      {
        title: `Maecenas volutpat pellentesque ullamcorper.?`,
        description: `Morbi sed ipsum accumsan turpis fermentum imperdiet. Sed eget tortor at turpis varius blandit volutpat et lacus. Proin a risus dolor.`
      }
    ]
  },
  {
    type: 'Safety Recalls',
    id: 'Safety',
    items: [
      {
        title: `Is my data secure?`,
        description: `Absolutely. We use [specific security protocols, e.g., end-to-end encryption, SSL certification] to ensure your data remains safe.`
      },
      {
        title: `Can I cancel my subscription?`,
        description: `Yes, you can cancel your subscription at any time. Visit [account settings link] for details or contact support.`
      }
    ]
  },
  {
    type: 'Security',
    id: 'Security',
    items: [
      {
        title: `Are there any hidden fees?`,
        description: `No. All costs are clearly outlined in our pricing plans.`
      },
      {
        title: `Where can I learn more?`,
        description: `Check out our blog, tutorials, or community forum for more information.`
      }
    ]
  }
]

export default function Faq() {

  const [viewTitle, setViewTitle] = useState(null)

  return (
    <div className='perfect-body'>
      <div className='pb-5'>
        <div className='container'>
          <Header />
          <div className="mt-100">
            <div className="text-center">
              <h1 className="mb-3 main-heading color-dark mt-20">
                Frequently Asked Questions
              </h1>
              <p className='color-dark opacity-75'>
                Find answers or connect with a human.
              </p>
            </div>
          </div>
          {
            faqs.map((i, index) =>
              <div  id={i.id} key={index}>
                <div className='mt-80 pb-2'>
                  <YellowLine />
                  <p className='mb-0 ms-1 text-uppercase d-inline medium-heading weight-600'>
                    {i.type}
                  </p>
                </div>
                {
                  i.items.map((j, jindex) =>
                    <div className='mt-20 faq-box' key={jindex}>
                      <div className="d-flex gap-4 align-items-center justify-content-between pointer"
                        onClick={() => setViewTitle(viewTitle == j.title ? null : j.title)}>
                        <p className='mb-0 medium-height-heading color-dark'>{j.title}</p>
                        <div className='faq-round px-2 pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M11 1.5L6.70711 5.79289C6.37377 6.12623 6.20711 6.29289 6 6.29289C5.79289 6.29289 5.62623 6.12623 5.29289 5.79289L1 1.5" stroke="#252941" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                      {viewTitle == j.title && <p className='mt-20'>{j.description}</p>}
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      <SectionWaiting />
      <Footer />
    </div>
  )
}
