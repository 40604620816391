import React, { useEffect, useState } from 'react';
import { YellowLine } from '../svg';
import axios from 'axios';
import { ROOT_URL } from '../../utils/rootUrl';
import { MessageBox } from '../MessageBox';

export default function StepCarCondition({
  inputs,
  validations,
  setValidations,
  handleInputChange,
  setCurrentStep,
}) {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [activePage, setActivePage] = useState(0);
  const [totalStep, setTotalStep] = useState(1);

  const handleQuestionChange = (question, answer) => {
    const data = [...inputs.condition];
    const find = data.find((e) => e.queId === question.id);
    if (find) {
      const index = data.indexOf(find);
      data[index].ansId = answer.id;
      if (answer.que) {
        data[index].subQueId = answer.que.id;
        data[index].subAnsId = null;
      } else {
        delete data[index].subQueId;
        delete data[index].subAnsId;
      }
    } else {
      const newEntry = { queId: question.id, ansId: answer.id };
      if (answer.que) {
        newEntry.subQueId = answer.que.id;
        newEntry.subAnsId = null;
      }
      data.push(newEntry);
    }
    handleInputChange({ target: { name: 'condition', value: data } });
    setValidationErrors((prev) => ({ ...prev, [question.id]: false }));
  };

  const handleSubQuestionChange = (question, answer, inputValue) => {
    const data = [...inputs.condition];
    const find = data.find((e) => e.subQueId === question.id);
    if (find) {
      const index = data.indexOf(find);
      data[index].subAnsId = answer.id;
      if (answer.que) {
        data[index].sub2QueId = answer.que.id;
        data[index].sub2AnsId = null;
      } else {
        delete data[index].sub2QueId;
        delete data[index].sub2AnsId;
      }
      if (answer.sub_type == 'Input') data[index].inputValue = inputValue;
      else delete data[index].inputValue
    } else {
      data.push({ subQueId: question.id, subAnsId: answer.id, inputValue: answer.sub_type == 'Input' ? (inputValue || '') : undefined });
    }
    handleInputChange({ target: { name: 'condition', value: data } });
    setValidationErrors((prev) => ({
      ...prev,
      [question.parent_que_id]: false,
      [question.id]: false
    }));
  };

  const handleSub2QuestionChange = (question, answer) => {
    const data = [...inputs.condition];
    const find = data.find((e) => e.sub2QueId === question.id);
    if (find) {
      const index = data.indexOf(find);
      data[index].sub2AnsId = answer.id;
    } else {
      data.push({ sub2QueId: question.id, sub2AnsId: answer.id });
    }
    handleInputChange({ target: { name: 'condition', value: data } });
    setValidationErrors((prev) => ({
      ...prev,
      [question.parent_que_id]: false,
      [question.id]: false
    }));
  };

  const checkValidations = () => {
    if (questions?.length === 0) {
      MessageBox('error', 'No questions found, Please try after some time');
      return;
    }
    const errors = {};
    questions?.[activePage].forEach((q) => {
      const mainAnswer = inputs.condition.find((e) => e.queId === q.id);
      if (!mainAnswer) {
        errors[q.id] = true;
      } else if (mainAnswer.subQueId) {
        const subAnswer = inputs.condition.find(
          (e) => e.subQueId === mainAnswer.subQueId
        );
        if (!subAnswer || !subAnswer.subAnsId) {
          errors[mainAnswer.subQueId] = true;
        }
        if (('inputValue' in subAnswer) && !subAnswer.inputValue) {
          errors['input' + mainAnswer.subQueId] = true;
        }
        if (mainAnswer.sub2QueId) {
          const sub2Answer = inputs.condition.find(
            (e) => e.sub2QueId === mainAnswer.sub2QueId
          );
          if (!sub2Answer || !sub2Answer.sub2AnsId) {
            errors[mainAnswer.sub2QueId] = true;
          }
        }
      }
    });

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (activePage === totalStep - 1) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setActivePage((prev) => prev + 1);
      }
    } else {
      setValidations((prev) => ({ ...prev, condition: true }));
    }
  };

  const getQuestions = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${ROOT_URL}/new_question_list`);
      if (res.data.status === 'success') {
        setQuestions(res.data.data);
        setTotalStep(res.data.data?.length || 1);
      }
    } catch (err) {
      console.log(err);
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div>
      <h2 className='sub-heading mt-40 mb-0'>
        We found your car! <br className='d-lg-block' />
        Let’s confirm a few details.
      </h2>
      <div className='car-functionality mt-40'>
        <YellowLine />
        <p className='mb-0 ms-1 text-uppercase d-inline'>
          Car's{' '}
          <span className='color-purplr'>
            {questions[activePage]?.[0]?.pagename || 'condition'}
          </span>{' '}
          Details
        </p>
      </div>
      {
        loading ?
          <div className='mt-5'>Loading...</div>
          :
          questions?.[activePage]?.map((question, index) => (
            <div className='mt-30 car-condition' key={index}>
              <div className='heading-normal color-dark'>{question.que}</div>
              {
                question.example &&
                <div className='text_gray fs-14 mt-2 mb-3 store-text-size'>{question.example}</div>
              }
              {
                question.ans?.map((answer, jindex) => {
                  const isSelected = inputs.condition.find((e) => e.queId === question.id)?.ansId === answer.id;
                  return (
                    <button
                      className={`mt-2 rounded-small-graybtn${isSelected ? ' active' : ''}`}
                      onClick={() => handleQuestionChange(question, answer)}
                      key={jindex}
                    >
                      {answer.ans}
                    </button>
                  );
                })
              }
              {
                validationErrors[question.id] &&
                !inputs.condition.find((e) => e.subQueId === question.id) &&
                <div className='err_msg'>Please select an answer</div>
              }
              {
                question.ans?.map((answer, kindex) => {
                  const selectedMainAnswer = inputs.condition.find((e) => e.queId === question.id)?.ansId === answer.id;
                  if (selectedMainAnswer && answer.que) {
                    return (
                      <div key={kindex}>
                        <div className='mt-30 car-condition ms-4'>
                          <div className='heading-normal color-dark me-4'>
                            {answer.que.que}
                          </div>
                          {
                            answer.que.ans?.map((subAnswer, lindex) => {
                              const isSubSelected = inputs.condition.find((e) => e.subQueId === answer.que.id)?.subAnsId === subAnswer.id;
                              return (
                                <button
                                  className={`mt-2 rounded-small-graybtn${isSubSelected ? ' active' : ''}`}
                                  onClick={() => handleSubQuestionChange(answer.que, subAnswer)}
                                  key={lindex}
                                >
                                  {subAnswer.ans}
                                </button>
                              );
                            })
                          }
                          {
                            validationErrors[answer.que.id] &&
                            <div className='err_msg'>Please select an answer</div>
                          }
                          {
                            answer.que.ans?.map((subAnswer, lindex) => {
                              const isInput = subAnswer.sub_type == 'Input' && inputs.condition?.find((e) => e.subAnsId === subAnswer.id)
                              if (isInput) {
                                return (
                                  <div className='mt-3 ms-3' key={lindex}>
                                    <input
                                      className='form-control w-50'
                                      placeholder={subAnswer.que?.que || 'Other'}
                                      value={inputs.condition.find((e) => e.subQueId === answer.que.id)?.inputValue}
                                      onChange={e => handleSubQuestionChange(answer.que, subAnswer, e.target.value)}
                                    />
                                    {
                                      validationErrors['input' + answer.que.id] && !(inputs.condition?.find((e) => e.subAnsId === subAnswer.id)?.inputValue) &&
                                      <div className='err_msg'>Required*</div>
                                    }
                                  </div>
                                )
                              }
                            })
                          }
                          {
                            answer.que.ans?.map((subAnswer, mindex) => {
                              const selectedSubAnswer = subAnswer.sub_type !== 'Input' && inputs.condition.find((e) => e.subAnsId === subAnswer.id)?.subAnsId === subAnswer.id;
                              if (selectedSubAnswer && subAnswer.que) {
                                return (
                                  <div key={mindex}>
                                    <div className='mt-30 car-condition ms-4'>
                                      <div className='heading-normal color-dark me-4'>
                                        {subAnswer.que.que}
                                      </div>
                                      {
                                        subAnswer.que.ans?.map((sub2Answer, lindex) => {
                                          const isSubSelected = inputs.condition.find((e) => e.sub2AnsId === sub2Answer.id)?.sub2AnsId === sub2Answer.id
                                          return (
                                            <button
                                              className={`mt-2 rounded-small-graybtn${isSubSelected ? ' active' : ''}`}
                                              onClick={() => handleSub2QuestionChange(subAnswer.que, sub2Answer)}
                                              key={lindex}
                                            >
                                              {sub2Answer.ans}
                                            </button>
                                          );
                                        })
                                      }
                                      {
                                        validationErrors[subAnswer.que.id] &&
                                        <div className='err_msg'>Please select an answer</div>
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          ))
      }
      {
        validations.condition &&
        <div className='err_msg'>
          Please select answers for all above questions
        </div>
      }
      <div className='mt-40'>
        <button
          className='gray-btn border-0 btnyellow'
          onClick={checkValidations}
        >
          <span className='wx-170'>Next</span>
        </button>
      </div>
    </div>
  );
}
