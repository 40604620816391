import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'

export default function PrivacyPolicy() {
    return (<>
        <div className='perfect-body'>
            <div className='container'>
                <Header />
                <div className="mt-100">
                    <div className="text-center">
                        <span className="small-bas-heading color-purplr">Revised December 15, 2023</span>
                        <h1 className="mb-3 main-heading color-dark mt-20">
                            CarMax General Privacy Policy
                        </h1>
                        <p className="color-dark opacity-75">
                            Thank you for visiting the CarMax website (including its mobile device-ready version) or using the CarMax mobile app. In this Privacy Notice, we refer to our websites and mobile apps together as our “Online Services.” We hope that you find our Online Services to be a useful part of your car-buying experience. This Privacy Notice describes the types of personal information we collect from consumers through our Online Services and in connection with our products and services, including when you visit our CarMax stores. This notice also describes how we use the information, with whom we may share it, the choices available to you regarding our use of the information, the measures we take to protect the security of the information, and how you can contact us about our privacy practices.;
                        </p>
                        <p className="color-dark opacity-75">
                            Browse through our guide categories to discover everything you need to know about selling, valuing, running, and maintaining your car.
                        </p>
                        <p className="color-dark opacity-75">
                            Please note that this Privacy Notice describes our overall privacy practices for our Online Services that link to this notice. The Privacy Notice does not apply to any website or mobile app operated by CarMax that has a separate privacy policy or notice. This Privacy Notice does apply to information about consumers collected in CarMax stores and in other ways.
                        </p>
                        <p className="color-dark opacity-75">
                            Edmunds is an affiliate of CarMax that operates as a separate business with respect to the handling of personal information. Except as expressly addressed in this Policy or in other disclosures presented to you, Edmunds and CarMax do not share consumer personal information with each other. If you would like to exercise your rights with respect to Edmunds, please visit Edmunds.com.
                        </p>
                        <div className="mt-140 text-start">
                            <h2 className="mb-3 middel-heading color-dark">
                                Information we obtain
                            </h2>
                            <p className='color-dark opacity-75'>
                                We may obtain information from and about you in different ways. We obtain information from content you submit or provide to us through our Online Services (such as when you research a car) or in surveys, faxes, telephone calls, chat messages, emails, and other correspondence; from information you provide when you register for a MyCarMax account; from information you provide to us when you submit a lead about a car; test drive a car, buy a car from a CarMax store, have a car appraised, or sell a car to a CarMax store; from information you provide when you apply for financing; and from social media, such as social media handles, content and other data provided through third-party features (such as apps, tools, payment services, widgets, and plug-ins) or posted on social media pages (such as CarMax’s social media page or pages accessible to the public). The types of personal information we may obtain directly from you include:
                            </p>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light mb-3'>Information we obtain</h3>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Identifiers
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        , such as name, username and password, phone number, fax number, email and postal address, Social Security Number, driver’s license number, and social media handle;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Sensitive Personal Information,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        including your government identification number, such as social security number, login credentials, race or ethnic origin, marital status, payment information, and precise geolocation;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Commercial information,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as information about the transactions you conduct with us either online or in our stores (e.g., vehicle purchases, appraisals, and services), information about the vehicles that you have searched for or otherwise expressed or indicated an interest in, and payment information;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Professional information,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as information about your job and employer, salary information, and prior work history;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Demographics,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as military service, age, gender, marital status, date of birth, and other characteristics you provide to us via the Online Services or in our stores;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Records,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        of your interactions with our call center representatives, such as audio recordings;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Internet or other electronic activities,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as internet session information;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Preferences,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as preferred store and communications preferences; and
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Inferences,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        , drawn from the categories of personal information described above.
                                    </p>
                                </div>
                                <p className='color-dark opacity-75'>
                                    We also may collect other information in connection with our Online Services, products and services in ways that we describe at the time of collection or otherwise with your consent. As a reminder, if you are a consumer who provides personal information to us in connection with our financing services, please read our Financial Privacy Policy.
                                </p>
                            </div>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light mb-3'>Automated Collection of Data</h3>
                                <p className='color-dark opacity-75'>
                                    When you visit or interact with our Online Services or open our emails, we may obtain certain information by automated means. CarMax may use a variety of technologies to collect this information, such as browser cookies, flash cookies, web beacons, mobile device identifiers, server logs, and other technologies. A browser “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. A “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links web pages to web servers and their cookies and may be used to transmit information collected through cookies back to a web server. These technologies help us (1) remember your information so you will not have to re-enter it; (2) track and understand how you use and interact with our Online Services and third-party digital services; (3) tailor our Online Services around your preferences; (4) measure the usability of our Online Services; (5) understand the effectiveness of our communications; (6) identify, diagnose and resolve technical issues; and (7) otherwise manage and enhance our Online Services, products and services.
                                </p>
                                <p className='color-dark opacity-75'>
                                    The information we obtain in this manner may include:
                                </p>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Identifiers,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as your device IP address and identifiers associated with your devices and apps;                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Online activities,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        such as dates and times of website visits and app use, content viewed, your search terms, operating system and browser used, mouse clicks, and how you accessed the Online Services;
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <span className='color-dark'>
                                        Geolocation data,
                                    </span>
                                    <p className='color-dark opacity-75 d-inline'>
                                        When you use our Online Services, we may infer your location via your IP address or other information regarding your network connection. In addition, with your consent, our mobile device-ready website or mobile app (our “Mobile Services”) may collect precise information about the geographic location of your device. If your device is equipped with GPS or can connect with wireless access points or hot spots, or if your device is also a phone that communicates with cell towers or satellites, then your device is able to use these features to determine its precise geographic location. If you have consented using your device’s user interface, the geographic location of your device will be transmitted to our servers in real time any time that our mobile app is running(even if you are not actively using the app or it is minimized on your device). Once you set your device to transmit its location information to us, your device will continue to transmit its location information to us(when it is open) until you set your device to no longer do so. You may at any time opt out from allowing us to have access to your device’s location information by accessing our app’s location settings on your device and setting your device not to share its location with us.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-30">
                                <h3 className='small-heading color-light mb-3'>Third-party Sources</h3>
                                <p className='color-dark opacity-75'>
                                    We collect information from third parties, such as social media platforms, government agencies including the DMV, credit reporting agencies, reputation and identify verification services, vehicle history services, insurance companies, skip trace vendors, marketing vendors, data analytics companies and data suppliers. The categories of information that we collect about you from such sources are:
                                </p>
                                <ul>
                                    <li className='mb-2 color-dark opacity-75'>Identifiers;</li>
                                    <li className='mb-2 color-dark opacity-75'>Financial, medical, or health insurance information;</li>
                                    <li className='mb-2 color-dark opacity-75'>Demographics;</li>
                                    <li className='mb-2 color-dark opacity-75'>Commercial information;</li>
                                    <li className='mb-2 color-dark opacity-75'>Biometric information;</li>
                                    <li className='mb-2 color-dark opacity-75'>Online activities;</li>
                                    <li className='mb-2 color-dark opacity-75'>Geolocation data;</li>
                                    <li className='mb-2 color-dark opacity-75'>Professional information;</li>
                                    <li className='mb-2 color-dark opacity-75'>Education information;</li>
                                    <li className='mb-2 color-dark opacity-75'>Preferences; and</li>
                                    <li className='color-dark opacity-75'>Sensitive information, such as precise geolocation,
                                        government identifiers, and race or ethnic origin
                                    </li>
                                </ul>
                                <p className='color-dark opacity-75'>
                                    We receive from Edmunds information regarding your
                                    online activities on Edmunds.com, though we do not receive
                                    information that directly identifies you.
                                </p>
                            </div>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light mb-3'>
                                    How we use the information we obtain
                                </h3>
                                <div className='mb-2'>
                                    <p className='color-dark opacity-75'>
                                        We collect and use your personal information for the following business purposes:
                                    </p>
                                    <ul className='mt-20'>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Providing and operating our Online Services, products and
                                            services, such as to provide you with the results of your searches;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Processing, evaluating, and responding to requests, inquiries,
                                            and claims we receive in connection with our Online Services, products and services;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Entering into and finalizing our transactions with you, including
                                            vehicle purchases, appraisals, and vehicle service;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Creating, managing, and administering your MyCarMax account or
                                            CarMax Auto Finance account, including identifying and authenticating
                                            you so you may access your account or use certain features of our Online Services;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Providing customer and technical support;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Providing you with marketing materials, such as to send you
                                            information about newly available vehicles and special offers
                                            and telling you about new features or updates;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Providing you with information, notices, offers, brochures,
                                            and advertising on our and third-party websites and mobile apps,
                                            by email and text, and in other ways;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Communicating with you about and administering your participation
                                            in surveys, special events, and other offers and promotions;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Performing data analytics, market research, and other processing;
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Operating, evaluating and improving our business and Online Services (including developing new products and services; enhancing and improving our Online Services, products and services; managing our communications; measuring the effectiveness of our sales, advertising, communications and marketing; analyzing our customer base, Online Services, products and services; performing accounting, auditing and other internal functions; and connecting your interactions with our Customer Service Specialists, our website and our physical stores to enable you to progress in your car shopping, selling, financing and service journey as efficiently as possible);
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Protecting against, identifying, and preventing fraud and other criminal activity, claims and other liabilities; and
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Complying with applicable regulatory and legal requirements, relevant industry standards, and our policies, including this Privacy Notice and our Terms of Use; and
                                        </li>
                                        <li className='mb-2 color-dark opacity-75'>
                                            Responding to legal, court, or regulatory investigations or requests for information.
                                        </li>
                                    </ul>

                                    <p className='color-dark opacity-75 mb-3'>
                                        We also may use the information we obtain about you in other ways for which we provide specific notice and obtain your consent if required by applicable law.
                                    </p>
                                    <p className='color-dark opacity-75 mb-3'>
                                        In addition, we may combine information that we obtain about you. For example, we may combine:
                                    </p>
                                    <ul>
                                        <li className='color-dark opacity-75 mb-2'>
                                            Information that we have obtained offline, including in-person at our stores or over the phone, with information we obtain through our Online Services;
                                        </li>
                                        <li className='color-dark opacity-75 mb-2'>
                                            Information we obtain through automated means with information you submit to us;
                                        </li>
                                        <li className='color-dark opacity-75 mb-2'>
                                            Information about our transactions and experiences with you with other information we have collected from you;
                                        </li>
                                        <li className='color-dark opacity-75 mb-2'>
                                            Financial information we learn about you with other information we obtain; and
                                        </li>
                                        <li className='color-dark opacity-75'>
                                            Information we get from a third-party with information we already have.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light mb-3'>
                                    Third-Party Web Analytics Services
                                </h3>
                                <div className='mb-2'>
                                    <p className='color-dark opacity-75'>
                                        We use third-party online analytics services on our Online Services, including “Google Analytics” and Google reCAPTCHA v3 to collect information about use of our Online Services. <Link className='color-purplr'>
                                            Learn more about how Google uses information from sites or apps that use Google Analytics.
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light mb-3'>
                                    Online Tracking and Interest-Based Advertising
                                </h3>
                                <div className='mb-2'>
                                    <p className='color-dark opacity-75'>
                                        We collect information about your online activities over time and across different websites, apps and devices, including from third-party websites and apps. We also work with third-parties, such as ad networks and other service providers, which collect information about your online activities in this way. To do this, we (including the third-parties) may use browser cookies, web beacons, flash cookies, unique identifiers associated with your devices and apps, and other technologies. The information collected via these technologies is described in the  <Link className='color-purplr'>
                                            LearAutomated Collection of Data
                                        </Link> section above.;
                                    </p>
                                    <p className='color-dark opacity-75'>
                                        We and certain third parties display interest-based advertising using information gathered about you over time and across devices and third-party websites, apps and platforms. Interest-based advertising or “online behavioral advertising” includes ads served to you after you leave our website, encouraging you to return. They also include ads we think are relevant based on your shopping habits or online activities. These ads might be served on websites or on mobile apps. They might also be served in emails or other ways. We might serve these ads, or third parties may serve ads. They might be about our products or other companies’ products.
                                    </p>
                                    <p className='color-dark opacity-75'>
                                        To decide what is relevant to you, we and certain third parties, such as our ad networks and other service providers, use information you make available to us when you interact with us, our affiliates, and other third parties. We and certain third parties gather this information using tracking tools, such as those described above. For example, we or the third parties may look at your browsing behaviors across devices. We and the third parties also may look at these activities on our apps and platforms and the apps and platforms of others.
                                    </p>
                                    <p className='color-dark opacity-75'>
                                        We work with third parties who help gather this information and serve ads. These third parties might link your name, email address and other information to data they obtain. That might include past purchases made offline or online. Or, it might include online usage information.
                                    </p>
                                    <p className='color-dark opacity-75'>
                                        To learn how to opt out of certain ad network interest-based advertising in the U.S., please visit YourAdChoices and Network Marketing Initiative websites. Choices you make may be browser-specific and device-specific. In addition, your mobile device settings may allow you to limit your device from sharing certain information for advertising purposes. Learn more information on these types of settings by exploring <Link className='color-purplr'>
                                            Google Play Help - Advertising ID
                                        </Link>  and <Link className='color-purplr'>
                                            limiting your Apple ad tracking.
                                        </Link>  Additional information about opting out of targeted advertising is included in the <Link className='color-purplr'>Your Rights </Link> section below.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-80 text-start">
                            <h2 className="mb-3 middel-heading color-dark">
                                Information Sharing
                            </h2>
                            <p className="color-dark opacity-75 mt-30">
                                We may share your personal information with third parties for the business purposes described above in accordance with applicable law:
                            </p>
                            <ul className='mt-20'>
                                <li className="color-dark opacity-75">
                                    We may share information within the CarMax family of companies;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information with third parties who perform services for us or on our behalf. For example, we share information with vendors who send emails for us. We may also share information with companies that operate our websites or run a promotion. The information we share may include location information. We do not authorize our service providers to use or disclose the information except as necessary to perform services for us or on our behalf or to comply with legal requirements;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information with third parties to complete your transactions, including Departments of Motor Vehicles and third-party finance companies;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information if you are a winner of a sweepstakes, contest, or promotion. For example, we may share your information if you win a sweepstakes or contest as part of a winner’s list. We may also publish this winner’s list publicly;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information with our business partners. For example, we will share information with third parties who cosponsor a promotion. These partners may send you information about events and products by mail or email;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information if we think we are required to do so or believe that we have to do so in order to protect ourselves. For example, we may share information to respond to a court order or subpoena. We may share it in response to requests by a government agency or investigatory body. We may share information to establish, exercise or defend our legal rights or when we are investigating suspected or actual illegal activity or fraud;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information with any successor to all or part of our business. We reserve the right to transfer any information we have about you in the event we sell or transfer all or a portion of our business or assets(including in the event of a merger, acquisition, joint venture, reorganization, divestiture, dissolution or liquidation); and
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information for other reasons we may describe to you;
                                </li>
                                <li className="color-dark opacity-75 mt-2">
                                    We may share information with any other third party with your prior consent.
                                </li>
                            </ul>
                            <p className="color-dark opacity-75 mt-20">
                                We disclose the following categories of personal information for business and operational purposes, such as auditing transactions, data security, debugging and product improvement, customer service, fulfillment, marketing, advertising, analytics, processing transactions, and servicing and maintaining accounts:
                            </p>
                            <ul className='mt-20'>
                                <li className='color-dark opacity-75'>Identifiers;</li>
                                <li className='color-dark opacity-75 mt-2'>Commercial information;</li>
                                <li className='color-dark opacity-75 mt-2'>Online activities; </li>
                                <li className='color-dark opacity-75 mt-2'>Professional information;</li>
                                <li className='color-dark opacity-75 mt-2'>Geolocation data;</li>
                                <li className='color-dark opacity-75 mt-2'>Education information;</li>
                                <li className='color-dark opacity-75 mt-2'>Records of your interactions with us;</li>
                                <li className='color-dark opacity-75 mt-2'>Demographics; and</li>
                                <li className='color-dark opacity-75 mt-2'>Preferences</li>
                            </ul>
                            <p className="color-dark opacity-75 mt-20">
                                California law requires that we describe certain disclosures of personal information where we receive valuable consideration. California law considers a “sale” even if no money is exchanged, and disclosures of personal information for purposes of targeted advertising, considered “sharing” under California law. In the course of supporting CarMax digital advertising activities, we “sell” or “share information to digital marketing partners. They use this information to provide us with digital advertising services, and may use the information to improve the services and offerings they provide to us and other businesses. The information we “sell” or “share” in this manner includes information collected when you engage with our website, mobile application, and other digital offerings.
                            </p>
                            <p className="color-dark opacity-75 mt-20">
                                All the above information sharing practices exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties, excluding aggregators and providers of the text message services or as otherwise requested or required by court order, legal proceeding, investigative demand or any governmental agency or regulatory authority.
                            </p>
                        </div>
                        <div className="mt-80 text-start">
                            <h2 className="mb-3 middel-heading color-dark">
                                Choices
                            </h2>
                            <p className='color-dark opacity-75'>
                                We offer you certain choices in connection with the personal information we obtain about you. To update your preferences or limit the communications you receive from us, please contact us as specified in the <Link className='color-purplr'>
                                    How to contact us
                                </Link> section of this Privacy Notice.
                            </p>
                            <p className='color-dark opacity-75'>
                                When you use our mobile device-ready website or mobile app (our “Mobile Services”), we may assign a unique identifier to your mobile device which will enable us to identify your device and send you push notifications. If you wish to turn off push notifications, go to your device’s settings and turn off push notifications from the CarMax mobile app or turn off push notifications directly through the CarMax mobile app. You can stop all collection of information by our mobile app by uninstalling it. You may use the standard uninstall process on your mobile device or the mobile app marketplace or network.
                            </p>
                        </div>
                        <div className="mt-80 text-start">
                            <h2 className="mb-3 middel-heading color-dark">
                                Your Rights
                            </h2>
                            <p className='color-dark opacity-75'>
                                Residents of certain states, such as California, may have rights to submit certain requests regarding our processing of their information. Depending on where you reside, you may have some of the following rights with respect to your personal information, subject to applicable exceptions:
                            </p>
                            <p className='color-dark opacity-75'>
                                <span className='color-purplr'>  Right to Access or Know: </span>You may have the right to confirm that we have collected personal information about you and know what personal information we have collected about you, including, as applicable, the categories of personal information we have collected, the sources from which we collected that personal information, the business or commercial purposes for which we collected, sold, and shared that personal information, the categories of personal information that we sold, shared, or disclosed to third parties for business purposes and the categories of third parties to whom we sold, shared or disclosed personal information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SectionWaiting />
        <Footer />
    </>
    )
}
