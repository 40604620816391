import React, { useEffect, useState } from 'react'
import { Eye, Logo, ShowEye } from '../components/svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function Login() {

  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  })
  const [validations, setValidations] = useState({
    email: false,
    password: false
  })
  const [showPwd, setShowPwd] = useState(false)
  const [loading, setLoading] = useState()
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state) {
      setInputs(prev => ({
        ...prev,
        email: state.email,
        carID: state.carId
      }))
    }
  }, [state])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (!/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z0-9-]*[a-zA-Z])?$/.test(inputs.email)) {
      setValidations(prev => ({ ...prev, email: true }))
      return
    }
    if (inputs.password.length < 8) {
      setValidations(prev => ({ ...prev, password: true }))
      return
    }
    onLogin()
  }

  const onLogin = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/login`,
        inputs
      )
      MessageBox('success', res.data.msg)
      localStorage.setItem('full-name', (res.data?.user?.fname || '') + ' ' + (res.data?.user?.lname || ''))
      localStorage.setItem('fname', res.data?.user?.fname)
      localStorage.setItem('lname', res.data?.user?.lname)
      localStorage.setItem('email', res.data?.user?.email)
      localStorage.setItem('phone', res.data?.user?.mobileno)
      localStorage.setItem('userid', res.data?.user?.id)
      navigate('/dashboard')
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login-system'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className='yellow-card px-30 login-card mt-50'>
        <h1 className='sub-heading text-start'>
          Welcome Back! <br className='d-lg-block' />
          Sign into your Account
        </h1>
        <div className="mt-30">
          <div>
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              name='email'
              value={inputs.email}
              onChange={handleInputChange}
            />
            {validations.email && <div className='err_msg'>{inputs.email ? 'Invalid email' : 'Please enter email'}</div>}
          </div>
          <div className='mt-20 col-12'>
            <label className="form-label">Password</label>
            <div className='position-relative'>
              <input
                type={`${showPwd ? 'text' : 'password'}`}
                className="form-control"
                placeholder="Enter Your Password"
                name='password'
                value={inputs.password}
                onChange={handleInputChange}
              />
              {validations.password && <div className='err_msg'>{inputs.password ? 'Password must be 8 characters' : 'Please enter password'}</div>}
              <div className='pointer password_eye'>
                {
                  showPwd ?
                    <div onClick={() => setShowPwd(false)}>
                      <Eye />
                    </div>
                    :
                    <div onClick={() => setShowPwd(true)}>
                      <ShowEye />
                    </div>
                }
              </div>
            </div>
          </div>
          <Link to={'/forgot-password'} className='text-end d-block mt-2 color-purplr heding-normal'>Forgot your password?</Link>
        </div>
        <div className='mt-40'>
          <button className='gray-btn btnyellow border-0 w-100' onClick={checkValidations}>
            <span className='w-100'>{loading ? 'Loading...' : 'Sign In'}</span>
          </button>
        </div>
        <p className='mb-0 text-center mt-20'>
          Don't have an account yet?
          <Link className='color-purplr' to={'/register'}> Sign up</Link>
        </p>
      </div>
    </div>
  )
}
