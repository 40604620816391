import axios from "axios";
import React, { useState } from "react";
import { ROOT_URL } from "../../utils/rootUrl";
import { useNavigate } from "react-router-dom";
import { MessageBox } from "../MessageBox";

export default function CarValuation({ inputs, carValuation, carId }) {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAcceptOffer = () => {
    if (loading) return
    if (localStorage.getItem("userid")) {
      insertCar();
    } else {
      checkUser();
    }
  };

  const insertCar = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${ROOT_URL}/handleUpdatForm`, {
        userid: localStorage.getItem("userid"),
        carID: carId,
      });
      localStorage.removeItem("offer-details");
      localStorage.removeItem("current-step");
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const checkUser = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${ROOT_URL}/checkRegister`, {
        email: inputs.email,
      });
      localStorage.removeItem("offer-details");
      localStorage.removeItem("current-step");
      navigate("/" + res.data?.msg?.toLowerCase() || "/login", {
        state: { ...inputs, carId },
      });
    } catch (err) {
      console.log(err);
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelOffer = () => {
    localStorage.removeItem("offer-details");
    localStorage.removeItem("current-step");
    navigate("/");
  };

  return (
    <div className="row justify-content-center">
      {
        // inputs.condition?.[0]?.ansId != 2 ?
          <>
            <div className="col-12">
              <div className="text-center mt-5">
                <span className="color-dark">
                  <b>Your Car's Valuation</b>
                </span>
                <h1 className="main-heading color-yellow my-3">
                  ${carValuation > 0 ? parseInt(carValuation) : 0}
                </h1>
              </div>
            </div>
            <div className="col-12 text-center">
              <button
                className="gray-btn border-0 btnyellow mb-1"
                onClick={handleAcceptOffer}
              >
                <span>{loading ? 'Loading...' : 'Accept Offer'}</span>
              </button>
            </div>
          </>
          // :
          // <>
          //   <div className="col-12">
          //     <div className="text-center mt-5 mb-1">
          //       <span className="color-dark">
          //         <b>
          //           To receive an accurate car offer, an inspection is required. It ensures precise assessment and reflects true value.
          //         </b>
          //       </span>
          //     </div>
          //     <div className="col-12">
          //       <div className="text-center mb-1 mt-4">
          //         <button
          //           className="gray-btn border-0 btnyellow"
          //           disabled
          //         >
          //           <span>Book Your Inspection</span>
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // </>
      }
      <div className="col-12 text-center mt-2">
        <button className="gray-btn border-0" onClick={handleCancelOffer}>
          <span>Cancel Offer</span>
        </button>
      </div>
    </div>
  );
}
