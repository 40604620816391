import React, { useState } from 'react'
import { Logo } from '../components/svg'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function Verify() {

  const [inputs, setInputs] = useState({
    otp: ''
  })
  const [validations, setValidations] = useState({
    otp: false
  })
  const [loading, setLoading] = useState()
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    if (name === 'otp' && !/^[0-9]+$|^$/.test(value)) return
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (inputs.otp.length < 6) {
      setValidations(prev => ({ ...prev, otp: true }))
      return
    }
    verifyOTP()
  }

  const verifyOTP = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/otpVerify`,
        {
          email: state.email,
          type: state.type,
          carID: state.carId,
          verifyCode: inputs.otp
        }
      )
      MessageBox('success', res.data.msg)
      if (state.type === 'REGISTER') {
        localStorage.setItem('full-name', (res.data?.user?.fname || '') + ' ' + (res.data?.user?.lname || ''))
        localStorage.setItem('fname', res.data?.user?.fname)
        localStorage.setItem('lname', res.data?.user?.lname)
        localStorage.setItem('email', res.data?.user?.email)
        localStorage.setItem('phone', res.data?.user?.mobileno)
        localStorage.setItem('userid', res.data?.user?.id)
        navigate('/dashboard')
      }
      if (state.type === 'FORGOT') navigate('/reset-password', { state: { ...inputs, email: state.email } })
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const resendOTP = async () => {
    try {
      const res = await axios.post(
        `${ROOT_URL}/OtpSend`,
        {
          email: state.email,
          type: state.type
        }
      )
      MessageBox('success', res.data.msg)
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    }
  }

  return (
    <div className='login-system h_100vh'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className='yellow-card px-30 login-card mt-50'>
        <h1 className='sub-heading text-start'>
          Enter Your Verification <br className='d-lg-block' />
          6 Digit Code
        </h1>
        <div className="mt-30">
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Code"
              autoFocus
              maxLength={6}
              name='otp'
              value={inputs.otp}
              onChange={handleInputChange}
            />
            {validations.otp && <div className='err_msg'>{inputs.otp ? 'OTP must be contains 6 digits' : 'Please enter OTP'}</div>}
          </div>
        </div>
        <div className='mt-40'>
          <button className='gray-btn border-0 w-100' onClick={checkValidations}>
            <span className='w-100'>{loading ? 'Loading...' : 'Verify'}</span>
          </button>
        </div>
        <p className='mb-0 text-center mt-20'>
          <p className='color-purplr pointer' onClick={resendOTP}>Resend OTP</p>
        </p>
      </div>
    </div>
  )
}
