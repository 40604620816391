import React, { useState } from 'react'
import { Eye, Logo, ShowEye } from '../components/svg'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function ResetPassword() {

  const [inputs, setInputs] = useState({
    password: '',
    confirm_password: ''
  })
  const [validations, setValidations] = useState({
    password: false,
    confirm_password: false
  })
  const [showPwd1, setShowPwd1] = useState(false)
  const [showPwd2, setShowPwd2] = useState(false)
  const [loading, setLoading] = useState()
  const { state } = useLocation()
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (inputs.password.length < 8) {
      setValidations(prev => ({ ...prev, password: true }))
      return
    }
    if (inputs.password !== inputs.confirm_password) {
      setValidations(prev => ({ ...prev, confirm_password: true }))
      return
    }
    onResetPassword()
  }

  const onResetPassword = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/resetPassword`,
        {
          email: state.email,
          password: inputs.password,
          verifyCode: state.otp
        }
      )
      MessageBox('success', res.data.msg)
      navigate('/login')
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login-system h_100vh'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className='yellow-card px-30 login-card mt-50'>
        <h1 className='sub-heading text-start'>
          Reset password
        </h1>
        <div className="mt-30">
          <div className='col-12'>
            <label className="form-label">Enter New Password</label>
            <div className='position-relative'>
              <input
                type={`${showPwd1 ? 'text' : 'password'}`}
                className="form-control"
                placeholder="Enter Your Password"
                name='password'
                value={inputs.password}
                onChange={handleInputChange}
              />
              {validations.password && <div className='err_msg'>{inputs.password ? 'Password must be 8 characters' : 'Please enter password'}</div>}
              <div className='pointer password_eye'>
                {
                  showPwd1 ?
                    <div onClick={() => setShowPwd1(false)}>
                      <Eye />
                    </div>
                    :
                    <div onClick={() => setShowPwd1(true)}>
                      <ShowEye />
                    </div>
                }
              </div>
            </div>
          </div>
          <div className='mt-20 col-12'>
            <label className="form-label">Enter Conform Password</label>
            <div className='position-relative'>
              <input
                type={`${showPwd2 ? 'text' : 'password'}`}
                className="form-control"
                placeholder="Confirm Password"
                name='confirm_password'
                value={inputs.confirm_password}
                onChange={handleInputChange}
              />
              {validations.confirm_password && <div className='err_msg'>{inputs.confirm_password ? 'Password does not match' : 'Please confirm password'}</div>}
              <div className='pointer password_eye'>
                {
                  showPwd2 ?
                    <div onClick={() => setShowPwd2(false)}>
                      <Eye />
                    </div>
                    :
                    <div onClick={() => setShowPwd2(true)}>
                      <ShowEye />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='mt-40'>
          <button className='gray-btn border-0 w-100' onClick={checkValidations}>
            <span className='w-100'>{loading ? 'Loading...' : 'Reset'}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
