import React from 'react'
import SectionHero from '../components/SectionHero'
import Processing from '../components/Processing'
import WhyPerfectSell from '../components/WhyPerfectSell'
import TopDeals from '../components/TopDeals'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import CarGuide from '../components/CarGuide'
import HomeFaq from '../components/HomeFaq'
export default function Home() {
  return (
    <>
      <SectionHero />
      <WhyPerfectSell />
      <SectionWaiting />
      <Footer />
    </>
  )
}
