import React from 'react'

export default function Processing() {
    return (
        <>
            <section className='padding-block-100 pt-md-5 pt-4'>
                <div className='container'>
                    <div className='text-center'>
                        {/* <div className="d-flex align-items-center lg-mb-5 mb-3 justify-content-center">
                        <h2 className='big-middel-heading fs-80 color-purplr'>Ready. Set. <span className='set-fix-sold'>sold</span></h2>
                        <img className='set-fix-wid-sold d-none' src="/assets/img/icons/soldicon.svg" alt="" />
                        </div>
                        <p className='color-dark resource_title weight-500  opacity-75'>Sell your car the right way. No nonsense. Totally free!</p> */}
                    </div>
                    <div className='row mt-60 gap_30 justify-content-lg-start justify-content-center'>
                        <div className='col-lg-4 md-mb-0 mb-3 col-md-6'>
                            <div className='proccess-card'>
                                <div className='proccess-image step-img text-center'>
                                    <img src='/assets/img/icons/step1.svg' alt='step 1' />
                                    <div className='card-box car-step'>
                                        <p className='text-uppercase'>Step 1</p>
                                    </div>
                                    {/* <img src='/assets/img/home/car4.webp' alt='cars' className='car-animation car-4' />
                                    <img src='/assets/img/home/car3.webp' alt='cars' className='car-animation car-3' />
                                    <img src='/assets/img/home/car2.webp' alt='cars' className='car-animation car-2' />
                                    <img src='/assets/img/home/car1.webp' alt='cars' className='car-animation car-1' />
                                    <img src='/assets/img/home/true.svg' alt='cars' className='true' /> */}

                                </div>
                                <div className='card-content'>
                                    <h3 className='sub-heading weight-500'>Get an Instant Price, Fast!</h3>
                                    <p className='color-dark m-0 opacity-75'>Enter your car’s information and instantly get a price.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-mb-0 mb-3 col-md-6'>
                            <div className='proccess-card'>
                                <div className='proccess-image step-img text-center'>
                                <img src='/assets/img/icons/step2.svg' alt='step 2' />
                                    <div className='card-box car-step'>
                                        <p className='text-uppercase'>Step 2</p>
                                    </div>
                                    {/* <img src='/assets/img/home/card/card1.webp' alt='cars' className='car-animation prise-animation' />
                                    <img src='/assets/img/home/true.svg' alt='cars' className='true price-true' /> */}
                                </div>
                                <div className='card-content'>
                                    <h3 className='sub-heading weight-500'>A few questions about your car.</h3>
                                    <p className='color-dark m-0 opacity-75'>Features like color and mileage affect your car’s value. If your vehicle has any boo boo's, be sure to include them.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-mb-0 mb-3 col-md-6'>
                            <div className='proccess-card'>
                                <div className='proccess-image step-img text-center'>
                                    <img src='/assets/img/icons/step3.svg' alt='step 3' />
                                    <div className='card-box car-step'>
                                        <p className='text-uppercase'>Step 3</p>
                                    </div>
                                    {/* <img src='/assets/img/home/card/sale.webp' alt='cars' className='car-animation sale' />
                                    <img src='/assets/img/home/card/coin1.webp' alt='cars' className='true sale1' />
                                    <img src='/assets/img/home/card/coin2.webp' alt='cars' className='true sale2' /> */}
                                </div>
                                <div className='card-content'>
                                    <h3 className='sub-heading weight-500'>Get Your Instant Cash Price™</h3>
                                    <p className='color-dark m-0 opacity-75'>Ready to sell? Get an offer from a local dealer today.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
