import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { Fb, Insta, Tweet } from '../components/svg'
import SectionHero from '../components/SectionHero'
import SectionWaiting from '../components/SectionWaiting'

export default function BlogDetail() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100'>
                        <h1 className='mb-3 main-heading color-dark'>
                            2025 Toyota 4Runner <span className='color-border'> Unveiled With Turbo Power,</span> Classic Style
                        </h1>
                        <p className='mb-3 color-dark opacity-75'>
                            Revamped for the first time in over a decade, the mid-size
                            SUV stays true to its four-wheeling mission.
                        </p>
                        <div className='mt-60'>
                            <img src="/assets/img/blog/blog1.webp" alt="" className='img-fluid' />
                        </div>
                        <p className='mt-60 color-dark opacity-75'>
                            The 4Runner trades last year’s 4.0-liter V6 for a lineup of turbocharged 4-cylinders.
                            The base 2.4-liter setup is rated at 278 hp and 317 lb-ft of torque, small bumps over
                            the 270 hp/278 lb-ft ratings of the V6. However, the turbocharged engine should give
                            a broader torque spread, meaning improved passing power.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Optional on some mid-trim versions and standard at the top of the range is a
                            hybrid version of this setup, which takes the 2.4-liter turbo-4 and adds a
                            small electric motor fed by a nickel-metal-hydride battery. The electric boost
                            ups the ante to 326 hp and a hearty 465 lb-ft of torque.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            All models use an 8-speed automatic transmission; don’t look for the
                            rumored 6-speed manual gearbox that’s available in the closely related
                            Toyota Tacoma.
                        </p>
                        <p className='color-dark opacity-75 mt-30 mb-0'>
                            Rear-wheel drive again comes standard, while a pair of two-speed
                            transfer cases are available or standard, depending on the trim.
                            A basic part-time unit is swapped out for a full-time setup in the hybrid.
                        </p>
                        <div className='mt-60'>
                            <img src="/assets/img/blog/blog2.webp" alt="" className='img-fluid' />
                        </div>
                        <p className='mt-60 color-dark opacity-75'>
                            The 4Runner’s 9.2 inches of ground clearance should ensure that
                            even a base SR5 trim can scramble down a trail reasonably well.
                            Step up to the TRD Off-Road and Toyota tosses in a locking rear
                            differential. TRD Pro and a new overland-oriented Trailhunter
                            version both have special springs and shocks for even more
                            clearance and improved big bump absorption. Gone is the
                            previously optional Kinetic Dynamic Suspension System
                            with its hydraulically actuated sway bars, but in its
                            place is an available sway bar disconnect system.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Some four-by-four models will include Toyota’s Crawl
                            Control system that uses the brakes to lope the 4Runner
                            along at a snail’s pace over obstacles. Various off-road
                            drive modes for different types of terrain are also available.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            The redesigned 4Runner doesn’t bring much new to the
                            table when it comes to convenience and driver-assistance
                            tech, though it’s not lacking much, either.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Lower-end versions use an 8.0-inch touchscreen that grows to 14.0
                            inches as you work your way up the lineup. All models use Toyota-developed
                            software with app-based navigation and standard wireless Apple CarPlay and
                            Android Auto compatibility. USB-C ports come standard up front, while
                            additional ports can be added as you work your way up the lineup.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Automatic emergency braking with pedestrian detection, adaptive
                            cruise control, and active lane control come standard; blind-spot
                            monitors are optional.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Additionally, drivers can set up a mobile phone to work as a key
                            to lock, unlock, and start the 4Runner. Users can even transfer access
                            to a trusted individual should they need to share the vehicle.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            A new Trailhunter trim combines some of the best-known aftermarket brands
                            in a single package oriented toward drivers who want to use their SUVs to
                            go camping. Underneath, look for Old Man Emu shocks and increased ground
                            clearance. Big 33-inch all-terrain tires grip the road—or the dirt. Up
                            top, an ARB roof rack stands ready for heavy-duty use, while an LED light
                            bar and Rigid LED fog lights point the way forward at night.
                        </p>
                        <div className="row align-items-center mt-50">
                            <div className="col-lg-6 col-md-7">
                                <button className='small-gray-btn'>
                                    SUV Cars
                                </button>
                                <button className='small-gray-btn ms-2'>
                                    Turbo Power
                                </button>
                                <button className='small-gray-btn ms-2'>
                                    North America
                                </button>
                            </div>
                            <div className='col-lg-6 col-md-5 text-end'>
                                <div className="d-flex align-items-center justify-content-md-end gap-2 mt-md-0 mt-4">
                                    <p className='mb-0 color-dark'>Share:</p>
                                    <Link>
                                        <Fb />
                                    </Link>
                                    <Link>
                                        <Insta />
                                    </Link>
                                    <Link>
                                        <Tweet />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SectionWaiting />
            <Footer />
        </>
    )
}
