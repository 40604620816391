import React, { useEffect } from 'react'
import Header from '../components/Header'
import {
    Do1,
    Do2,
    Do3,
    Do4,
    Do5,
    Do6,
    Purpose1,
    Purpose2,
    Purpose3,
    RightArrow,
    SliderSher,
} from '../components/svg'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import Animation from '../components/Animation'

const PurposeCom = ({ icon, text, Bgcolor }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <div
                className="box-rounded w-100 mb-4"
                style={{ backgroundColor: Bgcolor }}
            >
                <p
                    className="small-bas-heading color-light mb-0 britti-sans text-capitalize"
                    style={{ letterSpacing: '0' }}
                >
                    {icon} &nbsp; {text}
                </p>
            </div>
        </>
    )
}

const WeDoCard = ({ icon, text, tital }) => {
    return (
        <>
            <div className="we-do-card">
                <div className="d-flex align-items-center do-card gap-3">
                    <span>{icon}</span>
                    <div>
                        <h6 className="middel-heading color-dark-purpal mb-2 mt-1 weight-500">
                            {tital}
                        </h6>
                        <p className="opacity-75 mb-0">{text}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default function About() {
    return (
        <>
            <div className="hero-section about-section">
                <div className="container">
                    <Header className={'light-nav'} />
                    <div className="mt-140">
                        <h1 className="main-heading line-normal">
                            There’s a lot to <br className="d-lg-block" />
                            love about PerfectSell
                        </h1>
                    </div>
                </div>
            </div>
            <div className="about-img position-relative">
                <div className="container">
                    <img
                        src="./assets/img/about/hero.webp"
                        alt="img"
                        className="img-fluid"
                    />
                </div>
            </div>
            <div className="purpose-section">
                <div className="container">
                    <div className="padding-block-80">
                        <span className="small-bas-heading color-purplr">Our purpose</span>
                        <h2 className="main-heading color-dark mt-20 line-normal">
                            To drive integrity by being honest & transparent in every
                            interaction.
                        </h2>
                        <div className="mt-60">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-5">
                                    <PurposeCom
                                        icon={<Purpose1 />}
                                        text={'24-hour test Drives'}
                                    />
                                    <PurposeCom
                                        icon={<Purpose2 />}
                                        text={'Home Delivery'}
                                        Bgcolor={'#F6AE2D'}
                                    />
                                    <PurposeCom
                                        icon={<Purpose3 />}
                                        text={'Express Pickup'}
                                        Bgcolor={'#EF7733'}
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6">
                                    <h5 className="big-middel-heading mb-0">Who we are</h5>
                                    <p className="mt-30 mb-0 opacity-75">
                                        At Perfectsell, our commitment to innovation and iconic
                                        customer experiences have made us the nation's largest
                                        retailer of used cars. As the original disruptor of the
                                        automotive industry, our “no-haggle” prices transformed car
                                        buying and selling from a stressful, dreaded event into the
                                        honest, straightforward experience all people deserve. We've
                                        been raising the bar on customer experiences ever since.
                                    </p>
                                    <p className="mt-20 mb-0 opacity-75">
                                        Today, our customers have the ability to buy completely on
                                        their terms, whether that's online, in the store, or using a
                                        seamless combination of both. And as we move into the
                                        future, we stay committed to transparency, to creating
                                        offerings that put you in control, and delivering iconic car
                                        buying experiences unlike any you've seen before.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="what-we-do sell_bg padding-block-80">
                <div className="container">
                    <div className="text-center">
                        <p className="small-bas-heading color-purplr mb-0">
                            What matters to us
                        </p>
                        <h6 className="big-middel-heading mb-0 mt-3">
                            Above all, We care about People.
                        </h6>
                    </div>
                    <div className="row mt-60 g-4">
                        <div className="col-lg-6">
                            <div className="matter-card">
                                <div>
                                    <h6 className="sub-heading mb-3">Our Associates</h6>
                                    <p className="opacity-75 mb-0">
                                        Our success is possible because of the hard work and
                                        dedication of our
                                        <span className="color-purplr">
                                            30,000+ associates nationwide.
                                        </span>
                                        If you join our team, you'll join a culture of transparency,
                                        integrity, and an unwavering focus on doing what's right for
                                        each other, our customers, and our communities. We're
                                        committed to helping you innovate, learn, grow, and shape your
                                        career in ways you haven't even imagined.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src="./assets/img/about/matter1.webp"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="matter-card text-end">
                                <div className=''>
                                    <h6 className="sub-heading mb-3">Our customers</h6>
                                    <p className="opacity-75 mb-0">
                                        We put integrity and transparency at the heart of every
                                        interaction with you, whether that interaction is online, on
                                        the phone, or in person. We are committed to bringing clarity
                                        to each decision you make so you can feel confident about
                                        <span className="color-purplr"> buying the car you love</span>
                                        – and enjoy the ride while you do
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src="./assets/img/about/matter2.webp"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="matter-card">
                                <div>
                                    <h6 className="sub-heading mb-3">Our Communities</h6>
                                    <p className="opacity-75 mb-0">
                                        We invest both time and resources where we live and work, not
                                        only because it's the right thing to do, but also because we
                                        are passionate about helping our communities thrive. Between
                                        CarMax and The CarMax Foundation, we have invested
                                        <span className="color-purplr"> over $100 million </span>
                                        to date in causes important to our associates.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src="./assets/img/about/matter3.webp"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="matter-card text-end">
                                <div>
                                    <h6 className="sub-heading mb-3">
                                        Our commitment to diversity & inclusion
                                    </h6>
                                    <p className="opacity-75 mb-0">
                                        Our vision is for everyone, everywhere to have the same
                                        opportunity to reach their full potential. Our culture of
                                        <span className="color-purplr">
                                            {' '}
                                            innovation, diversity, and inclusion{' '}
                                        </span>
                                        is grounded in our core values, where winning together is
                                        driven by embracing and celebrating our differences. At
                                        CarMax, every associate is
                                        <span className="color-purplr">
                                            {' '}
                                            welcomed, supported, and valued{' '}
                                        </span>
                                        for their unique perspectives and backgrounds.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src="./assets/img/about/matter4.webp"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="what-we-do mt-140">

                <Animation />

            </div>
            <div className="what-we-do ">
                <div className="container">
                    <div className="text-center">
                        <p className="small-bas-heading color-purplr mb-0">What we do</p>
                        <h6 className="big-middel-heading mb-0 mt-3">What we do</h6>
                    </div>
                    <div className="row mt-60 g-md-4 g-3">
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard
                                icon={<Do1 />}
                                tital={'240+'}
                                text={'Stores in 41 states'}
                            />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard icon={<Do2 />} tital={'30,000+'} text={'Employees'} />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard
                                icon={<Do3 />}
                                tital={'45,000+'}
                                text={'PerfectSell Certified cars'}
                            />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard
                                icon={<Do4 />}
                                tital={'11+ million'}
                                text={'Cars sold'}
                            />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard
                                icon={<Do5 />}
                                tital={'42+ million'}
                                text={'cars appraised'}
                            />
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <WeDoCard
                                icon={<Do6 />}
                                tital={'12+ million'}
                                text={'cars purchased'}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center mt-140">
                        <div className="col-md-6 mb-md-0 mb-4">
                            <img
                                src="./assets/img/about/focus.webp"
                                alt="img"
                                className="img-fluid w-100"
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="ps-lg-5 ps-md-3">
                                <h6 className="big-middel-heading mb-0">
                                    We focus on our People
                                </h6>
                                <p className="opacity-75 mt-40 mt-sm-20">
                                    For nearly two decades, we have been a Fortune 100 Best
                                    Company to Work For®, and it’s because we truly value our
                                    people. We have more than 30,000 associates across more than
                                    240 retail locations nationwide, Customer Experience Centers
                                    in Phoenix, AZ, Atlanta, GA, Kansas City, MO, and Richmond,
                                    VA, CarMax Auto Finance in Atlanta, GA as well as CarMax
                                    Digital and Technology Innovation Center and CarMax Home
                                    Office in Richmond, VA.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-100">
                        <div className="col-md-6 order-md-1 order-2">
                            <div className="pe-lg-5 pe-md-3">
                                <h6 className="big-middel-heading mb-0">
                                    We focus on Innovation
                                </h6>
                                <p className="opacity-75 mt-40 mt-sm-20">
                                    Innovation and disruption are how we began, and they are part
                                    of the CarMax DNA. To this day, we encourage our associates to
                                    explore their curiosity and relentlessly pursue bold, new
                                    ideas for delivering experiences that are not only great, but
                                    iconic.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-2 order-1 mb-md-0 mb-4">
                            <img
                                src="./assets/img/about/Innovation.webp"
                                alt="img"
                                className="img-fluid w-100"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="carmax-eye">
                <div className="container">
                    <div className="mt-60 card-car-guide">
                        <div className="p-50 pb-0 text-center">
                            <h2 className="big-middel-heading color-light">
                                <span className="color-yellow">
                                    There’s More To CarMax Than
                                </span>{' '}
                                Meets The Eye
                            </h2>
                            <p className="mb-0 color-light-gray opicity-75">
                                Everything you need to know about selling, valuing, running and
                                maintaining your car
                            </p>

                            <div className="mt-80 text-start">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div
                                            className="waiting-card-for yellow-card
                                         perfect-hero-card p-20 w-100 aout-hovercard position-relative
                                         overflow-hidden"
                                        >
                                            <h4 className="small-heading weight-600">
                                                CarMax <br />
                                                Auto Finance
                                            </h4>
                                            <p className="medium-heading color-dark opacity-75 mb-0">
                                                Millions of car buyers have financed through CarMax.{' '}
                                            </p>
                                            <img src="./assets/img/about/a1.webp" alt="img" className='img-fluid w-100' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SectionWaiting />
            <Footer />
        </>
    )
}
