import React from 'react'
import Footer from '../components/Footer'
import SectionWaiting from '../components/SectionWaiting'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

export default function TrueStoriesDetail() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100'>
                        <h1 className='mb-3 main-heading color-dark'>
                            Danielle’s story:
                            <span className='color-border'>
                                a swift sale for a growing family.
                            </span>
                        </h1>
                        <p className='mb-3 color-dark opacity-75'>
                            Danielle sold her car just in time for her new arrival, who came a little earlier than expected.
                        </p>
                        <div className='mt-60'>
                            <img src="/assets/img/truestory/story1.webp" alt="" className='img-fluid' />
                        </div>
                        <p className='mt-60 color-dark opacity-75'>
                            Danielle had been happy with her Vauxhall Corsa for years. A small, three-door
                            car, it had reliably taken her from A to B without any issues. When she found
                            out she was pregnant, though, she and her husband worried about the safety
                            implications of having a baby’s car seat in the back without good access,
                            and decided to try to upgrade to a bigger car with more safety features.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            They visited some dealerships to research their next car, and got
                            a few trade-in offers, but ultimately decided they’d rather sell
                            their car for the best price possible, to have money available
                            to put down a deposit on a vehicle to suit their growing family.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Danielle looked at a few car selling sites, and her best
                            initial valuation was on CarMax. When she proceeded to profile
                            her car on the app, she found it very quick and easy. Feeling
                            tech-savvy, it took her just 10 minutes one afternoon to get
                            her interior and exterior shots, after she’d carefully washed
                            her car to have it looking its best, and then to take pictures of her ownership documents.
                        </p>
                        <p className='color-dark opacity-75 mt-30 mb-0'>
                            It was with excitement that Danielle and her husband waited
                            the 24 hours that their car was in CarMax’s online sale.
                            Then, when their best price came through and matched their
                            initial valuation, they were ecstatic. Being six months
                            pregnant at this stage, they wanted to move fast. They
                            had their eye on a car they were keen to purchase next, but
                            it unfortunately sold overnight.
                        </p>
                        <div className='mt-60'>
                            <img src="/assets/img/blog/blog2.webp" alt="" className='img-fluid' />
                        </div>
                        <p className='mt-60 color-dark opacity-75'>
                            Knowing that they were looking for a highly desirable car in a
                            competitive market, they were thrilled that their buyer was able
                            to come and inspect their car quickly. She carried out the appraisal
                            on the driveway and spoke to her colleagues at the dealership, and by
                            the time the dealer knocked on Danielle’s door, it was to say she’d
                            already paid the money straight into Danielle’s bank account!
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            That very same afternoon, Danielle went straight back to complete
                            the purchase of another car she had her eye on. And lucky too,
                            since her daughter was born five weeks early. It made the couple
                            glad they’d benefitted from a fast sale, so they were ready to
                            focus on welcoming their daughter and adjust to being first-time parents.
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Danielle’s husband drove her and their newborn girl home in a larger,
                            safer car with easy carseat access and enough space for all of their
                            new baby gear – a real relief after spending 12 days in hospital having
                            their premature baby cared for. The car’s first important outing was
                            taking the new family home for the first time, but soon they’ll be
                            packing it up again for a restful holiday in the Lake District!
                        </p>
                        <p className='color-dark opacity-75 mt-30'>
                            Are you due an upgrade to your vehicle? See what you could get by <Link className='color-purplr'>
                                selling your car on CarMax.
                            </Link>
                        </p>

                    </div>
                </div>
            </div>
            <SectionWaiting />
            <Footer />
        </>
    )
}
