import React from 'react'
import Header from '../components/Header'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import Box from '../components/Box'
import { Heand, Messageicone, OnlyFb, OnlyTweet, PhoneIcone, Social } from '../components/svg'
import CarHelpLine from '../components/CarHelpLine'
import Animation from '../components/Animation'


const tablist = [
    'Selling your car',
    'Valuing your car',
    'Car documentation & the DVLA',
    'Cars on finance',
    'Electric cars & alternative fuel vehicles'
]
const tablist2 = [
    'Car ownership',
    'Emissions & clean air zones',
    'Car checkers',
    'Car brands',
    'Selling updates'
]
export default function Guide() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className="mt-100">
                        <div className="text-center">
                            <h1 className="mb-3 main-heading color-dark mt-20">
                                Car selling <span className='color-border'>
                                    guides
                                </span>
                            </h1>
                            <p className='color-dark opacity-75'>
                                Here at CarMax, we’re big on cars and bigger on making sure customers get the best price when they sell! If the<br className='d-xl-block' />
                                idea of selling your car, dealing with the DVLA, figuring out Clean Air Zones, or making the big switch to an electric car has you confused,<br className='d-xl-block' />
                                we have a guide for you!
                            </p>
                            <p className='color-dark opacity-75'>
                                Browse through our guide categories to discover everything you need to know about selling, valuing, running, and maintaining your car.
                            </p>
                        </div>
                        <div className='mt-100 text-center'>
                            <h2 className="small-bas-heading color-purplr">Guide categories</h2>
                            <div className="d-flex justify-content-center align-items-center gap-3 mt-40 guid_over">
                                {tablist.map((i, index) =>
                                    <div className="tab-btn" key={index}>
                                        {i}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-20 gap-3 guid_over">
                                {tablist2.map((i, index) =>
                                    <div className="tab-btn" key={index}>
                                        {i}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-80 text-start">
                            <h2 className="mb-3 middel-heading color-dark">
                                Selling your car
                            </h2>
                            <div className="mt-60">
                                <CarHelpLine />
                            </div>
                        </div>
                        <div className="mt-80"></div>
                    </div>
                </div>
            </div>
            <Animation />
            <SectionWaiting />
            <Footer />
        </>
    )
}
