import React, { useEffect, useState } from 'react'
import { Eye, Logo, ShowEye } from '../components/svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export default function Register() {

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: ''
  })
  const [validations, setValidations] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
    password: false
  })
  const [showPwd, setShowPwd] = useState(false)
  const [loading, setLoading] = useState()
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state) {
      setInputs(prev => ({
        ...prev,
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        phone_number: state.phone_number,
        carId: state.carId
      }))
    }
  }, [state])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    if (name === 'phone_number' && !/^[0-9\s().+\-]+$|^$/.test(value)) {
      return
    }
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (!inputs.first_name) {
      setValidations(prev => ({ ...prev, first_name: true }))
      return
    }
    if (!inputs.last_name) {
      setValidations(prev => ({ ...prev, last_name: true }))
      return
    }
    if (!/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z0-9-]*[a-zA-Z])?$/.test(inputs.email)) {
      setValidations(prev => ({ ...prev, email: true }))
      return
    }
    if (!isPossiblePhoneNumber(String(inputs.phone_number), 'US')) {
      setValidations(prev => ({ ...prev, phone_number: true }))
      return
    }
    if (inputs.password.length < 8) {
      setValidations(prev => ({ ...prev, password: true }))
      return
    }
    onRegister()
  }

  const onRegister = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/register`,
        {
          fname: inputs.first_name,
          lname: inputs.last_name,
          email: inputs.email,
          mobileno: inputs.phone_number,
          password: inputs.password
        }
      )
      MessageBox('success', res.data.msg)
      navigate('/verify', { state: { ...inputs, type: 'REGISTER' } })
    } catch (err) {
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login-system'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className='yellow-card px-30 login-card mt-50'>
        <h1 className='sub-heading text-start'>
          Welcome!
          <br className='d-lg-block' />
          Sign Up for an Account
        </h1>
        <div className="mt-30 row">
          <div className='col-lg-6 col-12'>
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter First Name"
              name='first_name'
              value={inputs.first_name}
              onChange={handleInputChange}
            />
            {validations.first_name && <div className='err_msg'>Please enter first name</div>}
          </div>
          <div className='col-lg-6 col-12 mt-20 mt-lg-0'>
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Last Name"
              name='last_name'
              value={inputs.last_name}
              onChange={handleInputChange}
            />
            {validations.last_name && <div className='err_msg'>Please enter last name</div>}
          </div>
          <div className='col-12 mt-20'>
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              name='email'
              value={inputs.email}
              onChange={handleInputChange}
            />
            {validations.email && <div className='err_msg'>{inputs.email ? 'Invalid email' : 'Please enter email'}</div>}
          </div>
          <div className='col-12 mt-20'>
            <label className="form-label">Phone Number </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Phone Number"
              maxLength={15}
              name='phone_number'
              value={inputs.phone_number}
              onChange={handleInputChange}
            />
            {validations.phone_number && <div className='err_msg'>{inputs.phone_number ? 'Invalid USA phone number' : 'Please enter phone number'}</div>}
          </div>
          <div className='mt-20 col-12'>
            <label className="form-label">Password</label>
            <div className='position-relative'>
              <input
                type={`${showPwd ? 'text' : 'password'}`}
                className="form-control"
                placeholder="Enter Your Password"
                name='password'
                value={inputs.password}
                onChange={handleInputChange}
              />
              {validations.password && <div className='err_msg'>{inputs.password ? 'Password must be 8 characters' : 'Please enter password'}</div>}
              <div className='pointer password_eye'>
                {
                  showPwd ?
                    <div onClick={() => setShowPwd(false)}>
                      <Eye />
                    </div>
                    :
                    <div onClick={() => setShowPwd(true)}>
                      <ShowEye />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='mt-40'>
          <button className='gray-btn border-0 w-100' onClick={checkValidations}>
            <span className='w-100'>{loading ? 'Loading...' : 'Sign Up'}</span>
          </button>
        </div>
        <p className='mb-0 text-center mt-20'>
          Already have an account?
          <Link className='color-purplr' to={'/login'}> Sign in</Link>
        </p>
      </div>
    </div>
  )
}
