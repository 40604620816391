import React, { useEffect, useState } from 'react'

export default function PageAnimation() {

      const [isVisible, setIsVisible] = useState(true);
      const [shouldRender, setShouldRender] = useState(true);

      useEffect(() => {
            
            const timer = setTimeout(() => {
                  setIsVisible(false);
                  
                  setTimeout(() => {
                        setShouldRender(false);
                  }, 1000); 
            }, 3000);

            return () => clearTimeout(timer);
      }, []);

      if (!shouldRender) return null;


      return (
            <div id="transition-overlay" className={`transition-overlay ${isVisible ? "visible" : "hidden"}`}>
                   {/* className={`transition-overlay ${isVisible ? "visible" : "hidden"}`} */}
                  {/* Left SVG */}
                  <svg className='svg-left' xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                        <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                  </svg>
                  {/* Right SVG */}
                  <svg className='svg-right' xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                        <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                  </svg>
                  <div className="set_e_mix">
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                        <div className="align-mix-e">
                              <svg xmlns="http://www.w3.org/2000/svg" width={400} height={909} viewBox="0 0 400 909" fill="none">
                                    <path d="M157.815 462.474C157.815 286.27 98.4686 124.9 0 0H242.187C340.655 124.9 400 286.27 400 462.474C400 631.268 345.541 786.451 254.399 909H12.2124C103.354 786.451 157.815 631.269 157.815 462.474Z" fill="#6A1DEE" />
                              </svg>
                        </div>
                  </div>
            </div>

      )
}
