import React from 'react'
import { AbsIcon, AdjustableIcon, Aircon, AirConditionIcon, AlloyWheelIcon, AmFmIcon, AuxiliaryAudioInputIcon, BluetoothTechnologyIcon, CdAudioIcon, ClothSeatsIcon, CruiseControlIcon, FrontSeatHeaters, HeatedStreeringIcon, InchPlusWheelsIcon, LeatherSeatsIcon, MemorySeatIcon, NavigationSystemIcon, PanoramicSunroofIcons, ParkingSensorsIcon, PowerLocksIcon, PowerMirrorsIcon, PowerSeatIcon, PowerWindowsIcon, RearAirConditioningIcon, RearDefrostorIcon, RearSunshadeIcon, RearViewCameraIcon, RemoteStartIcon, Right, SideAirbagsIcon, SmartKeyIcon, TractionControlIcon, TurboChargedEngineIcon, UpgradeWheelsIcon, VinylSeatsIcon, YellowLine } from '../svg'

const features = [
  {
    name: 'ABS Brakes',
    icon: <AbsIcon />
  },
  {
    name: 'Air conditioning',
    icon: <AirConditionIcon />
  },
  {
    name: 'Upgrade Wheels',
    icon: <UpgradeWheelsIcon />
  },
  {
    name: 'Panoramic Sunroof',
    icon: <PanoramicSunroofIcons />
  },
  {
    name: 'Alloy Wheels',
    icon: <AlloyWheelIcon />
  },
  {
    name: 'AM/FM Stereo',
    icon: <AmFmIcon />
  },
  {
    name: 'CD Audio',
    icon: <CdAudioIcon />
  },
  {
    name: 'Cloth Seats',
    icon: <ClothSeatsIcon />
  },
  {
    name: 'Cruise Control',
    icon: <CruiseControlIcon />
  },
  {
    name: 'Heated Steering Wheel',
    icon: <HeatedStreeringIcon />
  },
  {
    name: 'Leather Seats',
    icon: <LeatherSeatsIcon />
  },
  {
    name: 'Power Locks',
    icon: <PowerLocksIcon />
  },
  {
    name: 'Power Mirrors',
    icon: <PowerMirrorsIcon />
  },
  {
    name: 'Power Seat(s)',
    icon: <PowerSeatIcon />
  },
  {
    name: 'Power Windows',
    icon: <PowerWindowsIcon />
  },
  {
    name: 'Traction Control',
    icon: <TractionControlIcon />
  },
  {
    name: 'Vinyl Seats',
    icon: <VinylSeatsIcon />
  },
  {
    name: '20 Inch Plus Wheels',
    icon: <InchPlusWheelsIcon />
  },
  {
    name: 'Adjustable Suspension',
    icon: <AdjustableIcon />
  },
  {
    name: 'Auxiliary Audio Input',
    icon: <AuxiliaryAudioInputIcon />
  },
  {
    name: 'Bluetooth Technology',
    icon: <BluetoothTechnologyIcon />
  },
  {
    name: 'Front Seat Heaters',
    icon: <FrontSeatHeaters />
  },
  {
    name: 'Memory Seat(s)',
    icon: <MemorySeatIcon />
  },
  {
    name: 'Navigation System',
    icon: <NavigationSystemIcon />
  },
  {
    name: 'Parking Sensors',
    icon: <ParkingSensorsIcon />
  },
  {
    name: 'Rear Air Conditioning',
    icon: <RearAirConditioningIcon />
  },
  {
    name: 'Rear Defroster',
    icon: <RearDefrostorIcon />
  },
  {
    name: 'Rear Seat Heaters',
    icon: <FrontSeatHeaters />
  },
  {
    name: 'Rear Sunshade',
    icon: <RearSunshadeIcon />
  },
  {
    name: 'Rear View Camera',
    icon: <RearViewCameraIcon />
  },
  {
    name: 'Remote Start',
    icon: <RemoteStartIcon />
  },
  {
    name: 'Side Airbags',
    icon: <SideAirbagsIcon />
  },
  {
    name: 'Smart Key',
    icon: <SmartKeyIcon />
  },
  {
    name: 'Turbo Charged Engine',
    icon: <TurboChargedEngineIcon />
  }
]

export default function StepFeatures({ inputs, validations, setValidations, handleInputChange, setCurrentStep }) {

  const handleFeatureSelect = (feature) => {
    const data = [...inputs.features]
    const find = data.find(e => e === feature)
    if (find) data.splice(data.indexOf(find), 1)
    else data.push(feature)
    handleInputChange({ target: { name: 'features', value: data } })
  }

  const checkValidations = () => {
    if (!inputs.features?.length) {
      setValidations(prev => ({ ...prev, features: true }))
      return
    }
    setCurrentStep(prev => (prev + 1))
  }

  return (
    <div>
      <h2 className='sub-heading mt-40 mb-0'>
        We found your car! <br className='d-lg-block' />
        Let’s confirm a few details.
      </h2>
      <div className='car-functionality mt-30'>
        <YellowLine />
        <p className='mb-0 ms-1 text-uppercase d-inline'>
          Select your car's <span className='color-purplr'>Features</span>
        </p>
      </div>
      <div className="row mt-30 g-4">
        {
          features.map((i, index) =>
            <div className="col-xl-6 col-lg-12 col-md-6 col-12" key={index}>
              <div
                className={`features-list${inputs.features?.includes(i.name) ? ` active` : ''}`}
                onClick={() => handleFeatureSelect(i.name)}
              >
                <div>
                  {i.icon}
                  <span className='ms-2'>{i.name}</span>
                </div>
                <button
                  type="button"
                  className="btn btn-sm  rounded-pill border-secondary d-flex juestify-content-center align-items-center p-1"
                  style={{ width: "17px", height: "17px" }}
                >
                  <div className='d-none'>
                    <Right />
                  </div>
                </button>
              </div>
            </div>
          )
        }
        {validations.features && <div className='err_msg'>Please select features</div>}
        <div className="col-md-12">
          <button className='gray-btn border-0 btnyellow' onClick={checkValidations}>
            <span className='wx-170'>Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}
