import React from 'react'
import { AnimationSvg, MyAnimation } from './svg'

export default function Animation() {
    return (
        <div className='animation_section'>
            <div className='container-fluid'>
                <div className="nifi-animation">
                    <span>
                        <img src="/assets/img/animation.svg" alt="" />
                    </span>
                </div>
            </div>
        </div>
    )
}
