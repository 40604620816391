import React from 'react'
import { Fb, Insta, LightLogo, Tweet } from './svg'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className='perfect-footer mt-0'>
            <div className='container'>
                <div className="row">
                    <div className="col-md-4 mb-lg-0 mb-4">
                        <a href="" className='footer_logo'>
                        <LightLogo />
                        </a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6">
                        <h6>About Instantcashprice</h6>
                        <ul>
                            <li>
                                <Link to="/about">About us</Link>
                            </li>
                            <li>
                                <Link to="/value-tracker#work"
                                onClick={() => {                                    
                                    setTimeout(() => {
                                        const docs = document.getElementById('work')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}
                                >How it work</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact us</Link>
                            </li>
                            <li>
                                <Link to="/true-story-detail">True Stories Details</Link>
                            </li>
                            {/* <li>
                                <Link to="">Press</Link>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-6 footer_menu pt-1">
                        <ul>
                            <li>
                                <Link to="">Careers</Link>
                            </li>
                            <li>
                                <Link to="">Refer a friend</Link>
                            </li>
                            <li>
                                <Link to="">More money stories</Link>
                            </li>
                            <li>
                                <Link to="">Reviews</Link>
                            </li>
                        </ul>
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-6">
                        <h6 className='mb-sm-3'>Selling your car</h6>
                        <ul>                        
                            <li>
                                <Link to="/#sellCar"
                                onClick={() => {                                    
                                    setTimeout(() => {
                                        const docs = document.getElementById('sellCar')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}
                                >Sell my car</Link>
                            </li>
                            <li>
                                <Link to="/#sellCar"
                                onClick={() => {                                    
                                    setTimeout(() => {
                                        const docs = document.getElementById('sellCar')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}
                                >Sell my electric car </Link>
                            </li>
                            <li>
                                <Link to="/#sellCar"
                                onClick={() => {                                    
                                    setTimeout(() => {
                                        const docs = document.getElementById('sellCar')
                                        window?.scrollTo(0, docs?.offsetTop - 150);
                                    }, 0);
                                }}
                                >Sell my van</Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-6 footer_menu mt-md-auto mt-4 pt-1">
                        <ul>
                            <li>
                                <Link to="">Sell to a dealer</Link>
                            </li>
                            <li>
                                <Link to="">Car buyres</Link>
                            </li>
                            <li>
                                <Link to="">Sell on finance </Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                        </ul>
                    </div> */}
                    <div className='col-12 text-center'>
                        <div className='d-flex gap-2'>
                            <Link>
                                <Fb />
                            </Link>
                            <Link>
                                <Insta />
                            </Link>
                            <Link>
                                <Tweet />
                            </Link>
                        </div>
                        <hr className="border-1  prefect_hr mt-30" />

                        <div className='d-flex flex-wrap justify-content-lg-between justify-content-center align-items-center mt-30'>
                            <div className='d-flex align-items-center mb-lg-0 mb-3'>
                                <Link className='color-light-gray text-decoration-none' to={'/terms-of-use'}>Terms</Link>
                                <span className='squary-pill'></span>
                                <Link className='color-light-gray text-decoration-none' to={'/privacy-policy'}>Privacy</Link>
                                <span className='squary-pill'></span>
                                <Link className='color-light-gray text-decoration-none' to={'/cookie-polices'}>Cookies</Link>
                                <span className='squary-pill'></span>
                                <Link className='color-light-gray text-decoration-none' to={'/'}>Sitemap</Link>
                            </div>
                            <span className='color-light-gray'>
                                Copyright © 2024 PerfectSell Enterprise Services, LLC
                            </span>
                        </div>
                        <hr className="border-1  prefect_hr mt-30" />
                        <p className='color-light-gray mt-30'>
                        *84% of Instant Cash Price sellers sold their vehicle for more than the average
                        market price valuation between Jan 2020 and October 2023.
                        </p>
                        <p className='color-light-gray mt-30'>
                        **Eligibility criteria for selling via our dealer network applies, based on vehicle
                        model, age, and mileage.
                        </p>
                        <p className='color-light-gray mt-30'>
                            †83% of payments are made on the day of handover.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
