import React, { useState } from 'react'
import { Logo } from '../components/svg'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function ForgotPassword() {

  const [inputs, setInputs] = useState({
    email: ''
  })
  const [validations, setValidations] = useState({
    email: false
  })
  const [loading, setLoading] = useState()
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (!/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z0-9-]*[a-zA-Z])?$/.test(inputs.email)) {
      setValidations(prev => ({ ...prev, email: true }))
      return
    }
    onContinue()
  }

  const onContinue = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/OtpSend`,
        {
          email: inputs.email,
          type: 'FORGOT'
        }
      )
      MessageBox('success', res.data.msg)
      navigate('/verify', { state: { ...inputs, type: 'FORGOT' } })
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login-system'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className='yellow-card px-30 login-card mt-50'>
        <h1 className='sub-heading text-start'>
          Forgot password
        </h1>
        <div className="mt-30">
          <div>
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              name='email'
              value={inputs.email}
              onChange={handleInputChange}
            />
            {validations.email && <div className='err_msg'>{inputs.email ? 'Invalid email' : 'Please enter email'}</div>}
          </div>
        </div>
        <div className='mt-40'>
          <button className='gray-btn border-0 w-100' onClick={checkValidations}>
            <span className='w-100'>{loading ? 'Loading...' : 'Continue'}</span>
          </button>
        </div>
        <p className='mb-0 text-center mt-20'>
          Back to
          <Link className='color-purplr' to={'/login'}> Sign in?</Link>
        </p>
      </div>
    </div>
  )
}
