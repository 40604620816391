import React from 'react'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import Header from '../components/Header'

export default function CookiePolices() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className='mt-100'>
                        <div className='text-center'>
                            <span className='small-bas-heading color-purplr'>Revised March 21, 2022</span>
                            <h1 className='mb-3 main-heading color-dark mt-20'>
                                Cookie polices
                            </h1>
                        </div>

                        <div className="mt-100">
                            <h2 className='mb-3 middel-heading color-dark'>Our use of cookies</h2>
                            <p className="color-dark opacity-75">
                                Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
                                with a good experience when you use our website and also allows us to improve our site. By continuing to
                                browse the site, you are agreeing to our use of cookies.
                            </p>
                        </div>
                        <div className="mt-80">
                            <h2 className='mb-3 middel-heading color-dark'>
                                What is a cookie?
                            </h2>
                            <p className="color-dark opacity-75">
                                At its simplest, a cookie is a small file that a website stores on your computer so it can recognise you
                                when you return to the website and remember something about you at a later date.
                            </p>
                        </div>
                        <div className='mt-80'>
                            <h2 className='middel-heading'>What kind of cookies does CarMax use?</h2>
                            <div className="mt-30">
                                <h3 className='small-heading color-light'>Strictly necessary cookies</h3>
                                <p className="color-dark opacity-75">
                                    These are cookies that are required for the operation of our website. They include, for example, cookies
                                    that enable you to sign into your CarMax account and see your offers again.
                                </p>
                            </div>
                            <div className="mt-30">
                                <h3 className='small-heading color-light'>Analytical/performance cookies</h3>
                                <p className="color-dark opacity-75">
                                    These cookies allow us to recognise and count the number of visitors and to see how visitors move around
                                    our website when they are using it. This helps us to improve the way our website works, for example, by
                                    ensuring that users are finding what they are looking for easily.
                                </p>
                            </div>
                            <div className='mt-30'>
                                <h3 className='small-heading color-light'>Functionality cookies</h3>
                                <p className="color-dark opacity-75">
                                    These are used to recognise you when you return to our website. This enables us to personalise our
                                    content for you, greet you by name and remember your preferences (for example, remembering the number
                                    plate you searched on CarMax so you don’t have to type it every time).
                                </p>
                            </div>
                            <div className="mt-30">
                                <h3 className='small-heading color-light'>Targeting cookies</h3>
                                <p className="color-dark opacity-75">
                                    These cookies record your visit to our website, the pages you have visited and the links you have
                                    followed. We will use this information to make our website and the advertising displayed on it more
                                    relevant to your interests. We may also share this information with third parties for this purpose.
                                </p>
                            </div>
                            <div className="mt-30">
                                <h3 className='small-heading color-light'>Third party cookies</h3>
                                <p className="color-dark opacity-75">
                                    Please note that third parties (including, for example, advertising networks and providers of external
                                    services like web traffic analysis services) may also use cookies, over which we have no control. These
                                    cookies are likely to be analytical/performance cookies or targeting cookies.
                                </p>
                            </div>
                        </div>
                        <div className='mt-80'>
                            <h2 className='mb-0 middel-heading color-dark'>
                                Opting-out of cookies
                            </h2>
                            <p className='mt-30 color-dark opacity-75 mb-0'>
                                While continued use of the CarMax website assumes compliance with our
                                current cookie policy, you can change your privacy settings at any time.
                            </p>
                            <p className='color-dark opacity-75 mt-3'>
                                Opting out of cookies is easy, but how do you do it depends on the browser
                                you’re using. The best guide to turning off cookies is available at
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            <SectionWaiting />
            <Footer />
        </>
    )
}
