import React, { useEffect } from 'react'
import Header from '../components/Header'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import Box from '../components/Box'
import { Heand, Location, Messageicone, OnlyFb, OnlyTweet, PhoneIcone, Social, YellowLine } from '../components/svg'
import CarHelpLine from '../components/CarHelpLine'
import { Link } from 'react-router-dom'

const tablist = [
    {
        title: 'Selling your car',
        count: '2',
        link: '/faq#Selling'
    },
    {
        title: 'Warranties & More',
        count: '2',
        link: '/faq#Warranties'
    },
    {
        title: 'Buying a Car',
        count: '3',
        link: '/faq#BuyingCard'
    },
    {
        title: 'Finding a Car',
        count: '2',
        link: '/faq#Finding'
    },
    {
        title: 'Selling a Car',
        count: '2',
        link: '/faq#SellingCard'
    },
    {
        title: 'Making a Payments',
        count: '2',
        link: '/faq#Making'
    },
    {
        title: 'Financing',
        count: '2',
        link: '/faq#Financing'
    },

    {
        title: 'Safety Recalls',
        count: '6',
        link: '/faq#Safety'
    },

    {
        title: 'Security',
        count: '11',
        link: '/faq#Security'
    }, 
    
    {
        title: 'Maintanance & more',
        count: '2',
        link: '/faq#Maintanance'
    }, 
]
const TabBtn = () => {
    return (
        <>
            {
                tablist.map((i, index) =>
                    <Link to={i.link} className="tab-btn" key={index} onClick={() => {                                    
                        setTimeout(() => {
                            const docs = document.getElementById(i.link.split('#')?.[1])
                            window?.scrollTo(0, docs?.offsetTop - 150);
                        }, 0);
                    }}>
                        {i.title}
                        <span class="badge contact-badget ms-lg-3 ms-2">
                            {i.count}
                        </span>
                    </Link>
                )
            }
        </>
    )
}

export default function ContactUs() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className="mt-100">
                        <div className="text-center">
                            <h1 className="mb-3 main-heading color-dark mt-20">
                                FAQ’s & Support
                            </h1>
                            <p className='color-dark opacity-75'>
                                Find answers or connect with a human.
                            </p>
                        </div>
                        <div className='mt-100 text-center'>
                            <h2 className="small-bas-heading color-purplr">Browse by topic</h2>
                            <div className="d-flex justify-content-center flex-wrap align-items-center gap-md-3 gap-2 mt-40">
                                <TabBtn />
                            </div>
                        </div>                        
                        <div className="mt-30">
                            <img src="./assets/img/contact/contact2.webp" alt="img" className='img-fluid' />
                        </div>
                    </div>
                    <div className="mt-80 text-center">
                        <p className="small-bas-heading color-purplr d-block pb-1">Contact us</p>

                        <h2 className="mb-3 middel-heading color-dark mt-20 d-block">
                            Reach a car-buying expert
                        </h2>
                        <p className='medium-heading opacity-75'>
                            Got questions on how to buy a car online or find affordable financing options? Don’t be shy, we’re here to assist you however you feel comfortable reaching out. Our PerfectSell agents are financing experts and can help craft a custom solution for your budget.
                        </p>
                        <div className="mt-60 text-start">
                            <CarHelpLine />
                        </div>
                    </div>
                    <div className="mt-80 text-center">
                        <p className="small-bas-heading color-purplr d-block pb-3">
                            Our office location
                        </p>
                        <h2 className="mb-3 middel-heading color-dark mt-20 d-block">
                            Other Resources
                        </h2>
                        <p className='medium-heading opacity-75'>
                            This one, just change it to Instant Cash Price, instead of True car
                        </p>
                        <div className="mt-60 pb-5">
                            <div className="row gap_30">
                                <div className="col-lg-3 col-md-6">
                                    <div className="resource-card text-start">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='car-functionality'>
                                                <YellowLine />
                                                <p className='mb-0 ms-1 text-uppercase d-inline'>
                                                    Corporate Home Office
                                                </p>
                                            </div>
                                            <Location />
                                        </div>
                                        <div>
                                            <p className='heding-normal mb-0 color-border weight-600'>Address</p>
                                            <h5 className='weight-500 opacity-75 mt-2 resource_title'>
                                                CarMax Home Office
                                                12800 Tuckahoe Creek Parkway Richmond, VA 23238
                                            </h5>
                                        </div>
                                        <divs>
                                            <p className='mb-1 opacity-75 heding-normal'>Phone no.</p>
                                            <p className='mb-0 color-purplr'>(800) 519-1511</p>
                                        </divs>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="resource-card text-start">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='car-functionality'>
                                                <YellowLine />
                                                <p className='mb-0 ms-1 text-uppercase d-inline'>
                                                    Marketing Vendoring Inquiries
                                                </p>
                                            </div>
                                            <Location />
                                        </div>
                                        <div>
                                            <p className='heding-normal mb-0 color-border weight-600'>Address</p>
                                            <h5 className='weight-500 opacity-75 mt-1 resource_title'>
                                                Attn: Marketing Solutions
                                                12800 Tuckahoe Creek Parkway
                                                Richmond, VA 23238
                                            </h5>
                                        </div>
                                        <div>
                                            <p className='mb-1 opacity-75 heding-normal'>Email:</p>
                                            <p className='mb-0 color-purplr text-break'>marketing_carmax@carmax.com</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="resource-card text-start">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='car-functionality'>
                                                <YellowLine />
                                                <p className='mb-0 ms-1 text-uppercase d-inline'>
                                                    The CarMax Foundation
                                                </p>
                                            </div>
                                            <Location />
                                        </div>
                                        <div>
                                            <p className='heding-normal mb-0 color-border weight-600'>Address</p>
                                            <h5 className='weight-500 opacity-75 mt-1 resource_title'>
                                                The CarMax Foundation
                                                Attn: Marketing, Foundation
                                                12800 Tuckahoe Creek Parkway
                                                Richmond, VA 23238
                                            </h5>
                                        </div>
                                        <div>
                                            <p className='mb-1 opacity-75 heding-normal'>Email:</p>
                                            <p className='mb-0 color-purplr'>kmxfoundation@carmax.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="resource-card text-start">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='car-functionality'>
                                                <YellowLine />
                                                <p className='mb-0 ms-1 text-uppercase d-inline'>
                                                    Supplier Inquiries
                                                </p>
                                            </div>
                                            <Location />
                                        </div>
                                        <div>
                                            <p className='heding-normal mb-0 color-border weight-600'>Address</p>
                                            <h5 className='weight-500 opacity-75 mt-1 resource_title'>
                                                Attn: Strategic Sourcing and Procurement
                                                12800 Tuckahoe Creek Parkway
                                                Richmond, VA 23238
                                            </h5>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xl-6 mb-xl-0 mb-3'>
                                                <div>
                                                    <p className='mb-1 opacity-75 heding-normal'>Phone no.</p>
                                                    <p className='mb-0 color-purplr'>(844) 765-5757</p>
                                                </div>
                                            </div>
                                            <div className='col-xl-6'>
                                                <p className='mb-1 opacity-75 heding-normal'>Email:</p>
                                                <p className='mb-0 color-purplr'>kmxfoundation@carmax.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="resource-card text-start">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='car-functionality'>
                                                <YellowLine />
                                                <p className='mb-0 ms-1 text-uppercase d-inline'>
                                                    Unclaimed Property
                                                </p>
                                            </div>
                                            <Location />
                                        </div>
                                        <div>
                                            <p className='heding-normal mb-0 color-border weight-600'>Address</p>
                                            <h5 className='weight-500 opacity-75 mt-1 resource_title'>
                                                Attn: Strategic Sourcing and Procurement
                                                12800 Tuckahoe Creek Parkway
                                                Richmond, VA 23238
                                            </h5>
                                        </div>
                                        <div className="row">
                                            <div className='col-xl-6 mb-xl-0 mb-3'>
                                                <p className='mb-1 opacity-75 heding-normal'>Phone no.</p>
                                                <p className='mb-0 color-purplr'>(844) 765-5757</p>
                                            </div>
                                            <div className='col-xl-6'>
                                                <p className='mb-1 opacity-75 heding-normal'>Email:</p>
                                                <p className='mb-0 color-purplr'>
                                                    stalecheck@carmax.com
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <SectionWaiting />
            <Footer />
        </>
    )
}
