import { Clock } from "../svg";

export default function CarCard({ data }) {
  return (
    <div className="col-xl-3 col-md-6 col-12">
      <div className="dashboard-card-border">
        <div className="img-card">
          <img
            src={data.img ? data.img : "./assets/img/dashboard/car.webp"}
            alt="alt"
            className="img-fluid w-10s0"
          />
        </div>
        <div className="mt-3">
          <h6 className="h6 color-dark weight-600">{data?.drivetype || "-"}</h6>
          <p className="heading-normal">
            VIN: <span className="opacity-75">{data?.vin_no || "-"}</span>
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="weight-600 mb-0 color-purplr">
              ${data?.valuation || 0}
            </h5>
            <p className="mb-0 weight-500 heding-normal">
              <Clock /> {data?.status || "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
