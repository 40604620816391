import React from 'react'
import { Logo } from '../components/svg'

export default function Welcome() {
    return (

        <div className='login-system text-center h_100vh'>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <Logo />
                    </div>
                    <div className="col-lg-7 col-md-9 col-11">
                        <div className='mt-100'>
                            <h1 className='main-heading color-dark'>
                                Welcome to <br />
                                <span className='color-yellow'>
                                    Instant Cash Price
                                </span>
                            </h1>
                            <p className='mt-20 mb-0'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Maecenas et facilisis odio, ac accumsan odio. Maecenas quis egestas sem,
                                Vivamus molestie, tellus eu sollicitudin mattis, mi urna tincidunt nisl,
                                sagittis ultricies tellus risus nec mauris.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}
