import React from 'react'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

export default function TermsOfUse() {
    return (
        <>
            <div className='perfect-body'>
                <div className='container'>
                    <Header />
                    <div className="mt-100">
                        <div className="text-center">
                            <span className="small-bas-heading color-purplr">Revised March 21, 2022</span>
                            <h1 className="mb-3 main-heading color-dark mt-20">
                                Terms of Use
                            </h1>
                            <p className="color-dark opacity-75">
                                Thank you for visiting carmax.com. CarMax Enterprise Services, LLC and its affiliates, excluding Edmunds.com, Inc. (referred to in these Terms of Use as “CarMax,” “us,” or “we”) provide websites, networks, mobile applications,
                                and other digital services to you when you visit or shop at carmax.com, use CarMax digital products or services, and use CarMax applications for mobile devices (collectively referred to as “Services”). CarMax provides the
                                Services subject to these “Terms of Use.” Edmunds.com, Inc. is an affiliate of CarMax that operates separate website(s), mobile application(s), and other digital services, which are governed by separate terms and policies.
                                Except as expressly addressed in these Terms of Use or in other disclosures presented to you, these Terms of Use do not control such Edmunds websites, applications, or services.
                            </p>
                            <p className="PLEASE-READ-THE">
                                <span className="text-uppercase color-dark opacity-75">
                                    PLEASE READ THE FOLLOWING CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS. THESE TERMS OF USE CONTAIN AN AGREEMENT TO ARBITRATE THAT MAY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES RATHER THAN IN A
                                    JURY TRIAL OR ANY OTHER COURT PROCEEDINGS. IT ALSO REQUIRES YOU TO GIVE UP YOUR RIGHT TO PARTICIPATION IN CLASS ACTIONS OF ANY KIND. THE ARBITRATION AGREEMENT IS FOUND IN THE{" "}
                                    <span className="color-purplr">“DISPUTES” SECTION </span>BELOW.
                                </span>
                            </p>
                            <div className="mt-140 text-start">
                                <h2 className="mb-3 middel-heading color-dark">Introduction</h2>
                                <p className="color-dark opacity-75">
                                    BY USING CARMAX’S SERVICES, YOU AGREE TO THESE TERMS OF USE, AS UPDATED FROM TIME-TO-TIME. The date of the most recent revisions will appear at the top of this page, so check back often.
                                </p>
                                <p className="color-dark opacity-75">
                                    These Terms of Use are binding on all individuals and entities who access, visit, and use the Services, including all persons, entities, and digital engines of any kind that harvest, crawl, index, scrape, spider, or mine
                                    digital content by an automated or manual process (collectively, “you” and “your”). Your use of the Services constitutes your acceptance of any changes and/or revisions to these Terms of Use. If you disagree with these Terms
                                    of Use, or if you violate, breach, fail to follow, or act inconsistently with these Terms of Use or any other terms and conditions that apply to the Services, then your access to and use of the Services is unauthorized, and
                                    CarMax reserves the right to terminate, suspend, and/or restrict your ability to access, visit and/or use the Services and to refuse to honor any of our purported obligations under these Terms of Use, with or without notice.
                                    In addition, we may restrict or refuse to provide you with future access, visitation, and/or use of the Services. We reserve the right, in addition to other remedies, to take any technical, legal or other actions we deem
                                    necessary, with or without notice, to prevent violations and to enforce these Terms of Use. As part of this, we reserve the right to involve and cooperate with law enforcement agencies, and to pursue a civil lawsuit or
                                    criminal prosecution for any and all alleged or actual illegal activities involving the Services.
                                </p>
                                <p className="color-dark opacity-75">
                                    CarMax may modify, suspend, discontinue, or terminate your right to use part or all of the Services at any time without notice to you, and in that event, we may modify the Services to make them inoperable. CarMax will not be
                                    liable to you should it exercise those rights.
                                </p>
                                <p className="color-dark opacity-75">
                                    Additional terms and conditions apply to some Services (for example, CarMax Auto Finance Online Account Services Agreement, E-Sign Consent and Agreement, <Link className='color-purplr'>Text Message Terms and Conditions</Link>), and these terms can be found where
                                    the relevant Service is offered and are incorporated into these Terms of Use by reference. If we provide specific terms of use for a particular Service and there is a conflict between the specific terms of use for the
                                    particular Service and these Terms of Use, the specific terms of use for the particular Service will govern.
                                </p>
                            </div>
                            <div className="mt-80 text-start">
                                <h2 className="mb-3 middel-heading color-dark">
                                    Eligibility & Jurisdiction; Content; License and Access
                                </h2>
                                <p className="color-dark opacity-75"></p>
                                <div>
                                    <h3 className='small-heading color-light'>Eligibility & Jurisdiction</h3>
                                    <p className="color-dark opacity-75">
                                        The Services are controlled and operated by CarMax from the United
                                        States and are not intended to subject CarMax to the laws or jurisdiction
                                        of any state, country, or territory other than the United States
                                        (excluding unincorporated territories, Puerto Rico, and Guam).
                                        CarMax does not represent or warrant that the Services or any
                                        part thereof is appropriate or available for use in any particular
                                        jurisdiction other than the United States. We may limit the Services’
                                        availability, in whole or in part, to any person, geographic area or
                                        jurisdiction we choose, at any time and in our sole discretion.
                                    </p>
                                    <p className="color-dark opacity-75">
                                        The Services are intended only for use in the United States by
                                        individuals who are 18 years of age or older and who have the
                                        capacity to enter into a valid contract. By accessing the Services
                                        you represent and affirm that you meet these requirements. If you choose
                                        to access this site from a location outside the United States, you are
                                        responsible for compliance with local laws.

                                    </p>
                                    <p className="color-dark opacity-75">
                                        CarMax does not knowingly or intentionally solicit or collect
                                        information from minors, and our marketing is not directed at minors.
                                    </p>
                                </div>
                                <div className="mt-20">
                                    <h3 className='small-heading color-light'>Content</h3>
                                    <p className="color-dark opacity-75">
                                        These Terms of Use govern all content on the Services, including text, articles, photographs, images, graphics, illustrations, creative, copy, artwork, video, audio, music, podcasts, trademarks, trade names, service marks, games, and other brand identifiers, designs, plans, software, source and object code, data, algorithms, analysis, statistics, indexes, registries, repositories, and all other content, information, and materials available on or through the Services, whether provided by CarMax, our licensors, partners vendors, and/or other service providers (“Content”).                                    </p>
                                </div>
                                <div className="mt-20">
                                    <h3 className='small-heading color-light'>License and Access</h3>
                                    <p className="color-dark opacity-75">
                                        Subject to your compliance with these Terms of Use, CarMax grants you a non-exclusive, limited, revocable, personal, non-transferrable license to use the Services and to download and use any CarMax mobile application (“Mobile Application”) on your mobile device, for your personal, NONCOMMERCIAL use only. As part of this, you may print, save, download, and share vehicle information, saved searches, your CarMax Auto Finance account information (subject to the below requirements), any paperwork or other information relating to your transaction, and the online credit application for your non-commercial, personal use only. This license does not include any resale or commercial use of any Services or its Content, any collection or use of any product listings, descriptions, or prices, any derivative use of any Services or its contents, any downloading, copying, or other use of account information for the benefit of any third party, or any use of data mining, robots, or similar data gathering and extraction tools.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-80 text-start">
                                <h2 className="mb-3 middel-heading color-dark">
                                    Prohibited Uses
                                </h2>
                                <p className="color-dark opacity-75">
                                    You may only use these Services for lawful purposes and in
                                    accordance with these Terms of Use. As a condition of your
                                    use of these Services, you warrant to CarMax that you will
                                    not use the Services for any purpose that is unlawful or
                                    prohibited by these Terms of Use. Whether on behalf of
                                    yourself or on behalf of a third party, you may NOT do the
                                    following in connection with the Services and Content:
                                </p>
                                <ul>
                                    <li className="color-dark opacity-75 mb-2">
                                        Access and/or use any other person’s User Information
                                        (defined below) or account, unless you have their express permission to do so.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Impersonate, imitate, or pretend to be someone else,
                                        by setting up different accounts or otherwise, or falsely
                                        state, represent, or imply any affiliation, association,
                                        or connection with a person or entity when using the
                                        Services.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Reproduce, duplicate, adapt, translate, copy, sell, resell, rent,
                                        visit, create derivative works of, timeshare,
                                        loan, distribute, otherwise exploit, or otherwise
                                        utilize any Services or Content (including product listings,
                                        descriptions, or prices), or any part thereof,
                                        for commercial purposes or for any purpose except those expressly
                                        authorized by these Terms of Use, without CarMax’s express written consent;
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Except as provided in these Terms of Use, download, copy or transmit
                                        any Content for the benefit of any third
                                        party or for use with another service without CarMax’s express written consent.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Make available any User Materials (defined below) through or in
                                        connection with the Services that is or may be
                                        in violation of the User Material Guidelines (set out below).
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Make available through or in connection with the Services any virus, worm,
                                        Trojan horse, Easter egg, time bomb,
                                        spyware, or other computer code, file or program that is or is potentially
                                        harmful or invasive or intended to
                                        damage or hijack the operation of, or to monitor the use of, any hardware,
                                        software, or equipment.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Interfere with or disrupt the operation of the Services
                                        or the systems, networks, or servers used to make the
                                        Services available, including by hacking or defacing
                                        any portion of the Services; or violate any procedure,
                                        policy, or requirements of such servers or networks.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Restrict or inhibit another person from using the Services.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Reverse engineer, decompile, or disassemble any portion of the Services, except where such restriction is
                                        expressly prohibited by applicable law.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Remove or modify any copyright or other intellectual property notices that appear in the Services.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Frame or mirror any portions of the Services, or otherwise incorporate or compile any portion of the Services
                                        into any product or service, unless you obtain CarMax’s express written consent.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Systematically download or store any materials except as expressly authorized by these Terms of Use.

                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Use any robot, spider, site search/retrieval application or other manual or automatic devices to retrieve,
                                        index, “scrape,” “data mine” or otherwise gather any Content, or reproduce or circumvent the navigational
                                        structure or presentation of the Services, without CarMax’s express prior written consent.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Cause injury to any person or entity.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Violate any law, rule, or regulation, or these Terms of Use.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Make any commercial, advertising, promotional, or marketing use of the Services and/or Content, except as
                                        permitted by law or as expressly permitted in writing by CarMax.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Use the Services or CarMax’s name, logo, or brand to send any unsolicited or unauthorized materials, including
                                        advertising, promotional materials, email, or other form of solicitation.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Use any meta tags or other hidden text or metadata utilizing a CarMax trademark, logo, URL, or product name
                                        without CarMax’s express written consent.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Harvest or collect personally identifiable information about other users of the Services.
                                    </li>
                                    <li className="color-dark opacity-75 mb-2">
                                        Attempt to do anything or permit, encourage, assist, or allow any third party to do anything prohibited by these
                                        Terms of Use.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <SectionWaiting />
            <Footer />
        </>
    )
}
