import React, { useEffect, useState } from "react";
import CarCard from "../components/dashboard/CarCard";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import Sidebar from "../components/dashboard/Sidebar";
import DashboardFooter from "../components/dashboard/DashboardFooter";
import axios from "axios";
import { ROOT_URL } from "../utils/rootUrl";
import { MessageBox } from "../components/MessageBox";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getDashboardData = async () => {
    try {
      const res = await axios.get(
        `${ROOT_URL}/carSellHistory?user_id=${
          localStorage.getItem("userid") || ""
        }`
      );
      setDashboardData(res.data?.data?.list || []);
    } catch (err) {
      console.log(err);
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userid")) getDashboardData();
    else navigate("/login");
  }, []);

  return (
    <>
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main className="col-xl-10 col-lg-9 ms-sm-auto px-md-4">
              <h1 className="h4 weight-600">Dashboard</h1>
              <p className="opacity-75">
                Get your latest update for the last 7 days
              </p>
              <div className="dashboard-card">
                <div className="row g-4">
                  {loading ? (
                    <div className="text-center mt-5 mb-4">Loading...</div>
                  ) : dashboardData.length ? (
                    dashboardData.map((i, index) => (
                      <CarCard data={i} key={index} />
                    ))
                  ) : (
                    <div className="text-center mt-5 mb-4">No Data Found</div>
                  )}
                </div>
              </div>
            </main>
            <DashboardFooter />
          </div>
        </div>
      </div>
    </>
  );
}
