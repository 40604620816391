import React from 'react'
import { Logo } from '../components/svg'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className='login-system'>
      <div className='text-center'>
        <Logo />
      </div>
      <div className="d-flex justify-content-between align-items-center h-100vh flex-column mt-100">
        <div className='mt-100 text-center'>
          <img src="./assets/img/not-found.webp" alt="img" className='img-fluid' />
          <h1 className='middel-heading mt-5'>
            Something Gone Wrong!
          </h1>
          <p className='mt-20'>
            The page you were looking for doesn’t exist
          </p>
          <div className='mt-40 mx-auto'>
            <button className='gray-btn border-0 btnyellow' onClick={() => navigate('/')}>
              <span>Go back</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
